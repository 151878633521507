import { getRoleById } from "../../../user/services/role.service"
import {getPrivileges} from '../../../user/services/privilege.service'

export const roleToEditLoader = async ({params}) => {

    try {
        const roleId = params.roleId

        const result = await getRoleById(roleId)
        const response = await result.json()
        if(!response.success){
            return {
                errorCode: result.status,
                loaderDetails: result.status === 404 ? 'The requested resource could not be found on this server. Please verify your request and try again.': response.message,
                role: null,
                allPrivileges: null,
                success: false,
                actionText: 'Go To Home Page',
                action: '/'
            }
        }

        const privilegesResult = await getPrivileges('', 1, 200, 'name', 'asc')
        const privilegesResponse = await privilegesResult.json()
        if(!privilegesResponse.success){
            return {
                errorCode: privilegesResult.status,
                loaderMessage: 'Page Not Found',
                loaderDetails: privilegesResult.status === 404 ? 'The requested resource could not be found on this server. Please verify your request and try again.': privilegesResponse.message,
                role: null,
                allPrivileges: null,
                success: false,
                actionText: 'Go To Home Page',
                action: '/'
            }
        }

        return {
            errorCode: 200,
            loaderMessage: 'OK',
            loaderDetails: response.message,
            role: response.data.role,
            allPrivileges: privilegesResponse.data.privileges,
            success: true,
            actionText: 'Success',
            action: ''
        }
        
    } catch (error) {
        return {
            errorCode: 0,
            loaderMessage: 'You are offline',
            loaderDetails: 'Please check your connection and try again.',
            role: null,
            allPrivileges: null,
            success: false,
            actionText: 'Retry',
            action: '/'
        }
    }
}

export const roleToAddLoader = async ({params}) => {
    try {
        const privilegesResult = await getPrivileges('', 1, 200, 'name', 'asc')
        const privilegesResponse = await privilegesResult.json()
        if(!privilegesResponse.success){
            return {
                errorCode: privilegesResult.status,
                loaderMessage: 'Page Not Found',
                loaderDetails: privilegesResult.status === 404 ? 'The requested resource could not be found on this server. Please verify your request and try again.': privilegesResponse.message,
                role: null,
                allPrivileges: null,
                success: false,
                actionText: 'Go To Home Page',
                action: '/'
            }
        }

        return {
            errorCode: 200,
            loaderMessage: 'OK',
            loaderDetails: privilegesResponse.message,
            role: null,
            allPrivileges: privilegesResponse.data.privileges,
            success: true,
            actionText: 'Success',
            action: ''
        }
        
    } catch (error) {
        return {
            errorCode: 0,
            loaderMessage: 'You are offline',
            loaderDetails: 'Please check your connection and try again.',
            role: null,
            allPrivileges: null,
            success: false,
            actionText: 'Retry',
            action: '/'
        }
    }
}