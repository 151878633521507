import { SERVER_URL } from "../../utils/server/server";
import { mainFetcher } from "../../utils/services/service";

export const getPostCategories = async (pageNo , pageSize, sortField, sortDir, search) => {
    
    const urlParams = 'pageNo=' + pageNo 
    +  '&pageSize=' + pageSize
    + '&sortField=' + sortField
    + '&sortDir=' + sortDir
    + '&search=' + search

    return mainFetcher('get', {}, SERVER_URL + '/api/post-categories/?' + urlParams )
};

export const updatePostCategory = async (postCategory) => {
    return mainFetcher('put', postCategory, SERVER_URL + '/api/post-categories/')
};

export const createPostCategory = async (postCategory) => {
    return mainFetcher('post', postCategory, SERVER_URL + '/api/post-categories/')
};

export const getPostCategory = async (postCategoryId) => {
    return mainFetcher('get', {}, SERVER_URL + '/api/post-categories/' + postCategoryId)
};

export const deletePostCategory = async (postCategoryId) => {
    return mainFetcher('delete', {}, SERVER_URL + '/api/post-categories/' + postCategoryId)
};

export const getPostParentCategories = async (pageNo , pageSize, sortField, sortDir, search) => {
    
    const urlParams = 'pageNo=' + pageNo 
    +  '&pageSize=' + pageSize
    + '&sortField=' + sortField
    + '&sortDir=' + sortDir
    + '&search=' + search

    return mainFetcher('get', {}, SERVER_URL + '/api/post-categories/parents?' + urlParams )
};

export const getDistinctPostCategoriesByPostUserId = async (userId, published, pageNo , pageSize, sortField, sortDir) => {
    published = published !== null ? 'published=' + published : ''
    
    const urlParams = 'pageNo=' + pageNo 
    +  '&pageSize=' + pageSize
    + '&sortField=' + sortField
    + '&sortDir=' + sortDir
    + published

    return mainFetcher('get', {}, SERVER_URL + '/api/post-categories/user/' + userId + '?' + urlParams )
};

export const getPostCategoriesByTotalPublishedPosts = async (totalPublishedPosts, pageNo , pageSize, sortField, sortDir) => {
    const urlParams = 'pageNo=' + pageNo 
    +  '&pageSize=' + pageSize
    + '&sortField=' + sortField
    + '&sortDir=' + sortDir

    return mainFetcher('get', {}, SERVER_URL + '/api/post-categories/find-by-total-published-posts/' + totalPublishedPosts + "?" +urlParams )
};

export const getPostCategoriesCount = async () => {
    return mainFetcher('get', {}, SERVER_URL + '/api/post-categories/count')
};

export const getPostParentCategoryChildren = async (parentId,pageNo , pageSize, sortField, sortDir, search) => {
    
    const urlParams = 'pageNo=' + pageNo 
    +  '&pageSize=' + pageSize
    + '&sortField=' + sortField
    + '&sortDir=' + sortDir
    + '&search=' + search

    return mainFetcher('get', {}, SERVER_URL + '/api/post-categories/children/' + parentId + '?' + urlParams )
};