import { SERVER_URL } from "../../utils/server/server";
import { mainFetcher } from "../../utils/services/service";



export const getSalesGenerateds = async (pageNo , pageSize, sortField, sortDir) => {

    const urlParams = 'pageNo=' + pageNo 
    +  '&pageSize=' + pageSize
    + '&sortField=' + sortField
    + '&sortDir=' + sortDir

    return mainFetcher('get', {}, SERVER_URL + '/api/sales-generated/?' + urlParams )
};

export const getSalesGeneratedByUserId = async (userId, pageNo , pageSize, sortField, sortDir) => {    
    const urlParams = 'pageNo=' + pageNo 
    +  '&pageSize=' + pageSize
    + '&sortField=' + sortField
    + '&sortDir=' + sortDir

    return mainFetcher('get', {}, SERVER_URL + '/api/sales-generated/user/' + userId + '?' + urlParams )
};

export const updateSalesGenerated = async (salesGenerated) => {
    return mainFetcher('put', salesGenerated, SERVER_URL + '/api/sales-generated/')
};

export const createSalesGenerated = async (salesGenerated) => {
    return mainFetcher('post', salesGenerated, SERVER_URL + '/api/sales-generated/')
};

export const getSalesGenerated = async (salesGeneratedId) => {
    return mainFetcher('get', {}, SERVER_URL + '/api/sales-generated/' + salesGeneratedId)
};

export const deleteSalesGenerated = async (salesGeneratedId) => {
    return mainFetcher('delete', {}, SERVER_URL + '/api/sales-generated/' + salesGeneratedId)
};

export const getSalesGeneratedByProjectId = async (projectId) => {

    return mainFetcher('get', {}, SERVER_URL + '/api/sales-generated/project/ ' + projectId )
};

export const deleteSalesGeneratedByProjectId = async (projectId) => {
    return mainFetcher('delete', {}, SERVER_URL + '/api/sales-generated/project/' + projectId)
};

export const getSalesGeneratedCount = async () => {
    return mainFetcher('get', {}, SERVER_URL + '/api/sales-generated/count')
};

export const getSalesGeneratedCountByUserId = async (userId) => {
    return mainFetcher('get', {}, SERVER_URL + '/api/sales-generated/count-by-user-id/' + userId)
};