export const DEFAULT_APP_PAGE_NO = 1
export const DEFAULT_APP_PAGE_SIZE = 24
export const DEFAULT_APP_SORT_DIRECTION = 'asc'
export const DEFAULT_APP_REVERSE_SORT_DIRECTION = 'desc'

export const DEFAULT_MANAGEMENT_PAGE_NO = 1
export const DEFAULT_MANAGEMENT_PAGE_SIZE = 24
export const DEFAULT_MANAGEMENT_SORT_DIRECTION = 'asc'
export const DEFAULT_MANAGEMENT_REVERSE_SORT_DIRECTION = 'desc'

export const TinyAPIKey = process.env.REACT_APP_TINY_API_KEY