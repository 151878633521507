import { CURRENT_USER_PROFILE_ACTION_TYPES } from "../types/current-user-profile.types"


const CURRENT_USER_PROFILE_INITIAL_STATE ={
    currentUserProfile : null
}

export const currentUserProfileReducer = (state = CURRENT_USER_PROFILE_INITIAL_STATE, action) => {
    const {type, payload} = action

    switch(type){
        case CURRENT_USER_PROFILE_ACTION_TYPES.SET_CURRENT_USER_PROFILE:
            return {...state , currentUserProfile: payload}
        default:
            return state
    }
} 