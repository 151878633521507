import { SERVER_URL } from "../../utils/server/server";
import { mainFetcher } from "../../utils/services/service";

export const getFeaturedProjects = async (published, pageNo , pageSize, sortField, sortDir) => {
    published = published !== null ? '&published=' + published : ''

    const urlParams = 'pageNo=' + pageNo 
    +  '&pageSize=' + pageSize
    + '&sortField=' + sortField
    + '&sortDir=' + sortDir
    + published

    return mainFetcher('get', {}, SERVER_URL + '/api/featured/?' + urlParams )
};

export const getActiveFeaturedProjects = async (published, pageNo , pageSize, sortField, sortDir) => {
    published = published !== null ? '&published=' + published : ''

    const urlParams = 'pageNo=' + pageNo 
    +  '&pageSize=' + pageSize
    + '&sortField=' + sortField
    + '&sortDir=' + sortDir
    + published

    return mainFetcher('get', {}, SERVER_URL + '/api/featured/active?' + urlParams )
};

export const updateFeatured = async (featured) => {
    return mainFetcher('put', featured, SERVER_URL + '/api/featured/')
};

export const createFeatured = async (featured) => {
    return mainFetcher('post', featured, SERVER_URL + '/api/featured/')
};

export const getFeatured = async (featuredId, published) => {
    published = published !== null ? 'published=' + published : ''

    const urlParams = published

    return mainFetcher('get', {}, SERVER_URL + '/api/featured/' + featuredId + '?' + urlParams)
};

export const deleteFeatured = async (featuredId) => {
    return mainFetcher('delete', {}, SERVER_URL + '/api/featured/' + featuredId)
};


export const getFeaturedByUserId = async (userId, published, pageNo , pageSize, sortField, sortDir) => {    
    published = published !== null ? '&published=' + published : ''

    const urlParams = 'pageNo=' + pageNo 
    +  '&pageSize=' + pageSize
    + '&sortField=' + sortField
    + '&sortDir=' + sortDir
    + published

    return mainFetcher('get', {}, SERVER_URL + '/api/featured/user/' + userId + '?' + urlParams )
};

export const getExpiredFeaturedByUserId = async (userId, published, pageNo , pageSize, sortField, sortDir) => {    
    published = published !== null ? '&published=' + published : ''

    const urlParams = 'pageNo=' + pageNo 
    +  '&pageSize=' + pageSize
    + '&sortField=' + sortField
    + '&sortDir=' + sortDir
    + published

    return mainFetcher('get', {}, SERVER_URL + '/api/featured/user/' + userId + '/expired?' + urlParams )
};

export const getActiveFeaturedByUserId = async (userId, published, pageNo , pageSize, sortField, sortDir) => {    
    published = published !== null ? '&published=' + published : ''

    const urlParams = 'pageNo=' + pageNo 
    +  '&pageSize=' + pageSize
    + '&sortField=' + sortField
    + '&sortDir=' + sortDir
    + published

    return mainFetcher('get', {}, SERVER_URL + '/api/featured/user/' + userId + '/active?' + urlParams )
};

export const getFeaturedCount = async () => {
    return mainFetcher('get', {}, SERVER_URL + '/api/featured/count')
};

export const getFeaturedCountByUserId = async (userId) => {
    return mainFetcher('get', {}, SERVER_URL + '/api/featured/count-by-user-id/' + userId)
};

export const getFeaturedByProjectId = async (projectId, published, pageNo , pageSize, sortField, sortDir) => {
    published = published !== null ? '&published=' + published : ''

    const urlParams = 'pageNo=' + pageNo 
    +  '&pageSize=' + pageSize
    + '&sortField=' + sortField
    + '&sortDir=' + sortDir
    + published

    return mainFetcher('get', {}, SERVER_URL + '/api/featured/project/ ' + projectId + '?' + urlParams )
};

export const deleteFeaturedByProjectId = async (projectId) => {
    return mainFetcher('delete', {}, SERVER_URL + '/api/featured/project/' + projectId)
};