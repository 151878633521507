import { getProjectByProjectName } from "../../../../projects/services/project.service"

export const projectLoader = async ({params}) => {

    try {
        const projectName = params.projectName

        const result = await getProjectByProjectName(projectName, true)
        const response = await result.json()
        if(!response.success){
            return {
                errorCode: result.status,
                message: 'Page Not Found',
                details: result.status === 404 ? 'The requested resource could not be found on this server. Please verify your request and try again.': response.message,
                project: null,
                success: false,
                actionText: 'View Projects',
                action: '/projects'
            }
        }

        return {
            errorCode: 200,
            message: 'OK',
            details: response.message,
            project: response.data.project,
            success: true,
            actionText: 'Success',
            action: ''
        }
        
    } catch (error) {
        console.error(error);
        return {
            errorCode: 0,
            message: 'You are offline',
            details: 'Please check your connection and try again.',
            project: null,
            success: false,
            actionText: 'Retry',
            action: '/projects'
        }
    }
}