import { getNotificationById } from "../../../notifications/notifications.service"

export const viewNotificationLoader = async ({params}) => {

    try {
        const notificationId = params.notificationId

        const result = await getNotificationById(notificationId)
        const response = await result.json()
        if(!response.success){
            return {
                errorCode: result.status,
                loaderMessage: 'Page Not Found',
                loaderDetails: result.status === 404 ? 'The requested resource could not be found on this server. Please verify your request and try again.': response.message,
                notification: null,
                success: false,
                actionText: 'Go To Home Page',
                action: '/'
            }
        }

        return {
            errorCode: 200,
            loaderMessage: 'OK',
            loaderDetails: response.message,
            notification: response.data.notification,
            success: true,
            actionText: 'Success',
            action: ''
        }
        
    } catch (error) {
        return {
            errorCode: 0,
            loaderMessage: 'You are offline',
            loaderDetails: 'Please check your connection and try again.',
            notification: null,
            success: false,
            postCategories: null,
            actionText: 'Retry',
            action: '/'
        }
    }
}