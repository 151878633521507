import { SERVER_URL } from "../../utils/server/server";
import { mainFetcher } from "../../utils/services/service";



export const getEfficiencies = async (pageNo , pageSize, sortField, sortDir) => {

    const urlParams = 'pageNo=' + pageNo 
    +  '&pageSize=' + pageSize
    + '&sortField=' + sortField
    + '&sortDir=' + sortDir

    return mainFetcher('get', {}, SERVER_URL + '/api/efficiencies/?' + urlParams )
};

export const getEfficienciesByUserId = async (userId, pageNo , pageSize, sortField, sortDir) => {    
    const urlParams = 'pageNo=' + pageNo 
    +  '&pageSize=' + pageSize
    + '&sortField=' + sortField
    + '&sortDir=' + sortDir

    return mainFetcher('get', {}, SERVER_URL + '/api/efficiencies/user/' + userId + '?' + urlParams )
};

export const updateEfficiency = async (efficiency) => {
    return mainFetcher('put', efficiency, SERVER_URL + '/api/efficiencies/')
};

export const createEfficiency = async (efficiency) => {
    return mainFetcher('post', efficiency, SERVER_URL + '/api/efficiencies/')
};

export const getEfficiency = async (efficiencyId) => {
    return mainFetcher('get', {}, SERVER_URL + '/api/efficiencies/' + efficiencyId)
};

export const deleteEfficiency = async (efficiencyId) => {
    return mainFetcher('delete', {}, SERVER_URL + '/api/efficiencies/' + efficiencyId)
};

export const getEfficiencyByProjectId = async (projectId) => {

    return mainFetcher('get', {}, SERVER_URL + '/api/efficiencies/project/' + projectId )
};

export const deleteEfficiencyByProjectId = async (projectId) => {
    return mainFetcher('delete', {}, SERVER_URL + '/api/efficiencies/project/' + projectId)
};

export const getEfficienciesCount = async () => {
    return mainFetcher('get', {}, SERVER_URL + '/api/efficiencies/count')
};

export const getEfficienciesCountByUserId = async (userId) => {
    return mainFetcher('get', {}, SERVER_URL + '/api/efficiencies/count-by-user-id/' + userId)
};