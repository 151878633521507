import { getCustomerSatisfactionByProjectId } from "../../../projects/services/project-customer-satisfaction.service"
import { getProject } from "../../../projects/services/project.service"

export const customerSatisfactionLoader = async ({params}) => {

    try {
        const projectId = params.projectId

        const customerSatisfactionResult = await getCustomerSatisfactionByProjectId(projectId)
        const customerSatisfactionResponse = await customerSatisfactionResult.json()
        if(customerSatisfactionResponse.success){
            return {
                errorCode: 200,
                loaderMessage: 'OK',
                loaderDetails: customerSatisfactionResponse.message,
                customerSatisfactionProject: customerSatisfactionResponse.data.customerSatisfaction.project,
                success: true,
                customerSatisfaction: customerSatisfactionResponse.data.customerSatisfaction,
                actionText: 'Success',
                action: '/'
            }
        }else{
            const result = await getProject(projectId, null)
            const response = await result.json()
            if(response.success){
                return {
                    errorCode: 200,
                    loaderMessage: 'OK',
                    loaderDetails: response.message,
                    customerSatisfactionProject: response.data.project,
                    success: true,
                    customerSatisfaction: null,
                    actionText: 'Success',
                    action: ''
                }
            }else{
                return {
                    errorCode: result.status,
                    loaderMessage: 'Page Not Found',
                    loaderDetails: result.status === 404 ? 'The requested resource could not be found on this server. Please verify your request and try again.': response.message,
                    customerSatisfactionProject: null,
                    success: false,
                    customerSatisfaction: null,
                    actionText: 'Go To Home Page',
                    action: '/'
                }
            }
        }
        
    } catch (error) {
        return {
            errorCode: 0,
            loaderMessage: 'You are offline',
            loaderDetails: 'Please check your connection and try again.',
            customerSatisfactionProject: null,
            success: false,
            customerSatisfaction: null,
            actionText: 'Retry',
            action: '/'
        }
    }
}