export const BEDUBYTE_ACTION_TYPES = {
    SET_BEDUBYTE: 'SET_BEDUBYTE',
    SET_BEDUBYTE_PROFILE: 'SET_BEDUBYTE_PROFILE',
    SET_BEDUBYTE_PAGINATION: 'SET_BEDUBYTE_PAGINATION',
    RESET_BEDUBYTE_PAGINATION: 'RESET_BEDUBYTE_PAGINATION',
    SET_BEDUBYTE_SEARCH: 'SET_BEDUBYTE_SEARCH',
    SET_BEDUBYTE_CURRENT_PAGE: 'SET_BEDUBYTE_CURRENT_PAGE',
    SET_APP_SOFT_REFRESH: 'SET_APP_SOFT_REFRESH',
    SET_SHARE_MODAL: 'SET_SHARE_MODAL',
    SET_NOTIFICATION_MODAL: 'SET_NOTIFICATION_MODAL'
}