import { SERVER_URL } from "../../utils/server/server";
import { mainFetcher } from "../../utils/services/service";



export const getCustomerSatisfactions = async (pageNo , pageSize, sortField, sortDir) => {

    const urlParams = 'pageNo=' + pageNo 
    +  '&pageSize=' + pageSize
    + '&sortField=' + sortField
    + '&sortDir=' + sortDir

    return mainFetcher('get', {}, SERVER_URL + '/api/customer-satisfactions/?' + urlParams )
};

export const getCustomerSatisfactionsByUserId = async (userId, pageNo , pageSize, sortField, sortDir) => {    
    const urlParams = 'pageNo=' + pageNo 
    +  '&pageSize=' + pageSize
    + '&sortField=' + sortField
    + '&sortDir=' + sortDir

    return mainFetcher('get', {}, SERVER_URL + '/api/customer-satisfactions/user/' + userId + '?' + urlParams )
};

export const updateCustomerSatisfaction = async (customerSatisfaction) => {
    return mainFetcher('put', customerSatisfaction, SERVER_URL + '/api/customer-satisfactions/')
};

export const createCustomerSatisfaction = async (customerSatisfaction) => {
    return mainFetcher('post', customerSatisfaction, SERVER_URL + '/api/customer-satisfactions/')
};

export const getCustomerSatisfaction = async (customerSatisfactionId) => {
    return mainFetcher('get', {}, SERVER_URL + '/api/customer-satisfactions/' + customerSatisfactionId)
};

export const deleteCustomerSatisfaction = async (customerSatisfactionId) => {
    return mainFetcher('delete', {}, SERVER_URL + '/api/customer-satisfactions/' + customerSatisfactionId)
};

export const getCustomerSatisfactionByProjectId = async (projectId) => {

    return mainFetcher('get', {}, SERVER_URL + '/api/customer-satisfactions/project/ ' + projectId )
};

export const deleteCustomerSatisfactionByProjectId = async (projectId) => {
    return mainFetcher('delete', {}, SERVER_URL + '/api/customer-satisfactions/project/' + projectId)
};

export const getCustomerSatisfactionsCount = async () => {
    return mainFetcher('get', {}, SERVER_URL + '/api/customer-satisfactions/count')
};

export const getCustomerSatisfactionsCountByUserId = async (userId) => {
    return mainFetcher('get', {}, SERVER_URL + '/api/customer-satisfactions/count-by-user-id/' + userId)
};