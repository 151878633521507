import { SERVER_URL } from "../../utils/server/server";
import { mainFetcher } from "../../utils/services/service";

export const getAwards = async (resumePriority, search, publicResume, publicProfile, pageNo , pageSize, sortField, sortDir) => {   
    resumePriority = resumePriority !== null ? '&resumePriority=' + resumePriority : ''
    search = search ? '&search=' + search : ''
    publicResume = publicResume !== null ? '&publicResume=' + publicResume : ''
    publicProfile = publicProfile !== null ? '&publicProfile=' + publicProfile : ''

    const urlParams = 'pageNo=' + pageNo 
    +  '&pageSize=' + pageSize
    + '&sortField=' + sortField
    + '&sortDir=' + sortDir
    + resumePriority
    + search
    + publicResume
    + publicProfile

    return mainFetcher('get', {}, SERVER_URL + '/api/award/?' + urlParams )
};

export const updateAward = async (award) => {
    return mainFetcher('put', award, SERVER_URL + '/api/award/')
};

export const createAward = async (award) => {
    return mainFetcher('post', award, SERVER_URL + '/api/award/')
};

export const getAward = async (awardId) => {
    return mainFetcher('get', {}, SERVER_URL + '/api/award/' + awardId)
};

export const deleteAward = async (awardId) => {
    return mainFetcher('delete', {}, SERVER_URL + '/api/award/' + awardId)
};

export const getAwardsByUserId = async (userId, resumePriority, search, pageNo , pageSize, sortField, sortDir) => {
    resumePriority = resumePriority !== null ? '&resumePriority=' + resumePriority : ''
    search = search ? '&search=' + search : ''

    const urlParams = 'pageNo=' + pageNo 
    +  '&pageSize=' + pageSize
    + '&sortField=' + sortField
    + '&sortDir=' + sortDir
    + resumePriority
    + search

    return mainFetcher('get', {}, SERVER_URL + '/api/award/user/' + userId + '?' + urlParams )
};

export const deleteAwardsByUserId = async (userId) => {
    return mainFetcher('delete', {}, SERVER_URL + '/api/award/' + userId)
};

export const countAwards = async () => {
    return mainFetcher('get', {}, SERVER_URL + '/api/award/count')
};

export const countAwardsByUserId = async (userId) => {
    return mainFetcher('get', {}, SERVER_URL + '/api/award/count-by-user-id/' + userId)
};