import { ERROR_ACTION_TYPES } from "../types/error.types"


const ERROR_INITIAL_STATE  = {
    code: 404,
    action: '/',
    actionText: 'Go To Home Page',
    details: 'The requested resource could not be found on this server. Please verify the address and try again.',
    message: 'Page Not Found',
    redirectTo: '/'
}

export const errorReducer = (state = ERROR_INITIAL_STATE, action) => {
    const {type, payload} = action

    switch(type){
        case ERROR_ACTION_TYPES.SET_ERROR:
            return {...payload}
        case ERROR_ACTION_TYPES.SET_ERROR_CODE:
            return getError(payload)
        case ERROR_ACTION_TYPES.SET_ERROR_ACTION:
            return {...state , action: payload}
        case ERROR_ACTION_TYPES.SET_ERROR_aCTION_TEXT:
            return {...state , actionText: payload}
        case ERROR_ACTION_TYPES.SET_ERROR_DETAILS:
            return {...state , details: payload}
        case ERROR_ACTION_TYPES.SET_ERROR_MESSAGE:
            return {...state , message: payload}
        case ERROR_ACTION_TYPES.SET_ERROR_REDIRECT_TO:
            return {...state , redirectTo: payload}
        case ERROR_ACTION_TYPES.RESET_ERROR:
            return ERROR_INITIAL_STATE
        default:
            return state
    }
} 


const getError = (code) =>{
    switch (code) {
        case 400:
                return {
                    code: 400,
                    action: '/',
                    actionText: 'Go To Home Page',
                    details: 'The syntax of the URL submitted by your browser could not be understood. Please verify the address and try again.',
                    message: 'Bad Request!',
                    redirectTo: '/'
                }
        case 401:
                return{
                    code: 401,
                    action: '/auth/login',
                    actionText: 'Go To Login Page',
                    details: 'This section requires a password or is otherwise protected. If you feel you have reached this page in error, please return to the login page and try again, or contact the webmaster if you continue to have problems.',
                    message: 'Unauthorized!',
                    redirectTo: '/'
                }
        case 403:
                return{
                    code: 403,
                    action: '/auth/login',
                    actionText: 'Go To Login Page',
                    details: 'You don\'t have the permission to access the requested resource. If you feel you have reached this page in error, please return to the login page and try again, or contact the webmaster if you continue to have problems.',
                    message: 'Forbidden!',
                    redirectTo: '/'
                }
        case 405:
                return{
                    code: 405,
                    action: '/',
                    actionText: 'Go To Home Page',
                    details: 'The method received in the request-line is known by the origin server but not supported by the target resource.',
                    message: 'Method Not Allowed!',
                    redirectTo: '/'
                }
        case 500:
                return{
                    code: 500,
                    action: '/',
                    actionText: 'Go To Home Page',
                    details: 'We encountered an error on the server side. We will solve this as soon as possible.',
                    message: 'Internal Server Error!',
                    redirectTo: '/'
                }
        default:
            return ERROR_INITIAL_STATE
    }
}