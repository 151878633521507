import { SERVER_URL } from "../../utils/server/server";
import { mainFetcher } from "../../utils/services/service";

export const getRevisionsEnum = async () => {
    return mainFetcher('get', {}, SERVER_URL + '/api/package-enum/revisions')
};

export const getNumberOfPagesEnum = async () => {
    return mainFetcher('get', {}, SERVER_URL + '/api/package-enum/number-of-pages')
};

export const getDeliveryTimeEnum = async () => {
    return mainFetcher('get', {}, SERVER_URL + '/api/package-enum/delivery-time')
};