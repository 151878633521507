import { getDeliveryTimeEnum, getNumberOfPagesEnum, getRevisionsEnum } from "../../../packages/services/package-enum.service"
import { getPackage } from "../../../packages/services/package.service"

export const packageToEditLoader = async ({params}) => {
    try {
        const packageId = params.packageId

        const result = await getPackage(packageId)
        const response = await result.json()
        if(!response.success){
            return {
                errorCode: result.status,
                loaderMessage: 'Page Not Found',
                loaderDetails: result.status === 404 ? 'The requested resource could not be found on this server. Please verify your request and try again.': response.message,
                aPackage: null,
                revisionsEnum: null,
                numberOfPagesEnum: null,
                deliveryTimeEnum: null,
                success: false,
                actionText: 'Go To Home Page',
                action: '/'
            }
        }

        const revisionsEnumResult = await getRevisionsEnum()
        const revisionsEnumResponse = await revisionsEnumResult.json()
        if(!revisionsEnumResponse.success){
            return {
                errorCode: revisionsEnumResult.status,
                loaderMessage: 'Page Not Found',
                loaderDetails: revisionsEnumResult.status === 404 ? 'The requested resource could not be found on this server. Please verify your request and try again.': revisionsEnumResponse.message,
                aPackage: null,
                revisionsEnum: null,
                numberOfPagesEnum: null,
                deliveryTimeEnum: null,
                success: false,
                actionText: 'Go To Home Page',
                action: '/'
            }
        }

        const numberOfPagesEnumResult = await getNumberOfPagesEnum()
        const numberOfPagesEnumResponse = await numberOfPagesEnumResult.json()
        if(!numberOfPagesEnumResponse.success){
            return {
                errorCode: numberOfPagesEnumResult.status,
                loaderMessage: 'Page Not Found',
                loaderDetails: numberOfPagesEnumResult.status === 404 ? 'The requested resource could not be found on this server. Please verify your request and try again.': numberOfPagesEnumResponse.message,
                aPackage: null,
                revisionsEnum: null,
                numberOfPagesEnum: null,
                deliveryTimeEnum: null,
                success: false,
                actionText: 'Go To Home Page',
                action: '/'
            }
        }

        const deliveryTimeEnumResult = await getDeliveryTimeEnum()
        const deliveryTimeEnumResponse = await deliveryTimeEnumResult.json()
        if(!deliveryTimeEnumResponse.success){
            return {
                errorCode: deliveryTimeEnumResult.status,
                loaderMessage: 'Page Not Found',
                loaderDetails: deliveryTimeEnumResult.status === 404 ? 'The requested resource could not be found on this server. Please verify your request and try again.': deliveryTimeEnumResponse.message,
                aPackage: null,
                revisionsEnum: null,
                numberOfPagesEnum: null,
                deliveryTimeEnum: null,
                success: false,
                actionText: 'Go To Home Page',
                action: '/'
            }
        }

        return {
            errorCode: 200,
            loaderMessage: 'OK',
            loaderDetails: response.message,
            aPackage: response.data.package,
            revisionsEnum: revisionsEnumResponse.data.revisionsEnum,
            numberOfPagesEnum: numberOfPagesEnumResponse.data.numberOfPagesEnum,
            deliveryTimeEnum: deliveryTimeEnumResponse.data.deliveryTimeEnum,
            success: true,
            actionText: 'Success',
            action: ''
        }
        
    } catch (error) {
        return {
            errorCode: 0,
            loaderMessage: 'You are offline',
            loaderDetails: 'Please check your connection and try again.',
            aPackage: null,
            revisionsEnum: null,
            numberOfPagesEnum: null,
            deliveryTimeEnum: null,
            success: false,
            actionText: 'Retry',
            action: '/'
        }
    }
}

export const packageToAddLoader = async ({params}) => {
    try {
        const revisionsEnumResult = await getRevisionsEnum()
        const revisionsEnumResponse = await revisionsEnumResult.json()
        if(!revisionsEnumResponse.success){
            return {
                errorCode: revisionsEnumResult.status,
                loaderMessage: 'Page Not Found',
                loaderDetails: revisionsEnumResult.status === 404 ? 'The requested resource could not be found on this server. Please verify your request and try again.': revisionsEnumResponse.message,
                aPackage: null,
                revisionsEnum: null,
                numberOfPagesEnum: null,
                deliveryTimeEnum: null,
                success: false,
                actionText: 'Go To Home Page',
                action: '/'
            }
        }

        const numberOfPagesEnumResult = await getNumberOfPagesEnum()
        const numberOfPagesEnumResponse = await numberOfPagesEnumResult.json()
        if(!numberOfPagesEnumResponse.success){
            return {
                errorCode: numberOfPagesEnumResult.status,
                loaderMessage: 'Page Not Found',
                loaderDetails: numberOfPagesEnumResult.status === 404 ? 'The requested resource could not be found on this server. Please verify your request and try again.': numberOfPagesEnumResponse.message,
                aPackage: null,
                revisionsEnum: null,
                numberOfPagesEnum: null,
                deliveryTimeEnum: null,
                success: false,
            }
        }

        const deliveryTimeEnumResult = await getDeliveryTimeEnum()
        const deliveryTimeEnumResponse = await deliveryTimeEnumResult.json()
        if(!deliveryTimeEnumResponse.success){
            return {
                errorCode: deliveryTimeEnumResult.status,
                loaderMessage: 'Page Not Found',
                loaderDetails: deliveryTimeEnumResult.status === 404 ? 'The requested resource could not be found on this server. Please verify your request and try again.': deliveryTimeEnumResponse.message,
                aPackage: null,
                revisionsEnum: null,
                numberOfPagesEnum: null,
                deliveryTimeEnum: null,
                success: false,
                actionText: 'Go To Home Page',
                action: '/'
            }
        }

        return {
            errorCode: 200,
            loaderMessage: 'OK',
            loaderDetails: revisionsEnumResponse.message,
            aPackage: null,
            revisionsEnum: revisionsEnumResponse.data.revisionsEnum,
            numberOfPagesEnum: numberOfPagesEnumResponse.data.numberOfPagesEnum,
            deliveryTimeEnum: deliveryTimeEnumResponse.data.deliveryTimeEnum,
            success: true,
            actionText: 'Success',
            action: ''
        }
        
    } catch (error) {
        return {
            errorCode: 0,
            loaderMessage: 'You are offline',
            loaderDetails: 'Please check your connection and try again.',
            aPackage: null,
            revisionsEnum: null,
            numberOfPagesEnum: null,
            deliveryTimeEnum: null,
            success: false,
            actionText: 'Retry',
            action: '/'
        }
    }
}