import { combineReducers } from "redux";
import { errorReducer } from "../app/reducers/error.reducer";

import { authReducer } from "../user/reducers/auth.reducer";
import { currentUserProfileReducer } from "../user/reducers/current-user-profile.reducer";
import { bedubyteReducer } from "../app/reducers/bedubyte.reducer";
import { contentHeaderReducer } from "../app/reducers/content-header.reducer";
import { managementReducer } from "../app/reducers/management.reducer";

export const rootReducer = combineReducers({
    auth: authReducer,
    currentUserProfile: currentUserProfileReducer,
    errors: errorReducer,
    bedubyte: bedubyteReducer,
    contentHeader: contentHeaderReducer,
    management: managementReducer
})