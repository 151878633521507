import { SERVER_URL } from "../../utils/server/server";
import { mainFetcher } from "../../utils/services/service";

export const getRoles = async (search, pageNo , pageSize, sortField, sortDir) => {
    
    const urlParams = 'pageNo=' + pageNo 
    +  '&pageSize=' + pageSize
    + '&sortField=' + sortField
    + '&sortDir=' + sortDir
    + '&search=' + search
    return mainFetcher('get', {}, SERVER_URL + '/api/roles/?' + urlParams )
};

export const createRole = async (role) => {
    return mainFetcher('post', role, SERVER_URL + '/api/roles/')
};

export const updateRole = async (role) => {
    return mainFetcher('put', role, SERVER_URL + '/api/roles/')
};

export const getRoleById = async (roleId) => {
    return mainFetcher('get', {}, SERVER_URL + '/api/roles/' + roleId)
};

export const deleteRoleById = async (roleId) => {
    return mainFetcher('delete', {}, SERVER_URL + '/api/roles/' + roleId)
};

export const getRoleCount = async () => {
    return mainFetcher('get', {}, SERVER_URL + '/api/roles/count')
};

export const getRolesByUserId = async (userId) => {
    return mainFetcher('get', {}, SERVER_URL + '/api/roles/user/' + userId)
};
