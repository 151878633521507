import { getPostCategory, getPostParentCategories } from "../../../blog/services/post-category.service"

export const postCategoryToEditLoader = async ({params}) => {

    try {
        const postCategoryId = params.postCategoryId

        const result = await getPostCategory(postCategoryId)
        const response = await result.json()
        if(!response.success){
            return {
                errorCode: result.status,
                loaderMessage: 'Page Not Found',
                loaderDetails: result.status === 404 ? 'The requested resource could not be found on this server. Please verify your request and try again.': response.message,
                postCategory: null,
                success: false,
                postCategories: null,
                actionText: 'Go To Home Page',
                action: '/'
            }
        }

        const postCategoriesResult = await getPostParentCategories(1 , 50, 'name', 'asc', '')
        const postCategoriesResponse = await postCategoriesResult.json()
        if(!postCategoriesResponse.success){
            return {
                errorCode: postCategoriesResult.status,
                loaderMessage: 'Page Not Found',
                loaderDetails: postCategoriesResult.status === 404 ? 'The requested resource could not be found on this server. Please verify your request and try again.': postCategoriesResponse.message,
                postCategory: null,
                success: false,
                postCategories: null,
                actionText: 'Go To Home Page',
                action: '/'
            }
        }

        return {
            errorCode: 200,
            loaderMessage: 'OK',
            loaderDetails: response.message,
            postCategory: response.data.postCategory,
            success: true,
            postCategories: postCategoriesResponse.data.postCategories,
            actionText: 'Success',
            action: ''
        }
        
    } catch (error) {
        return {
            errorCode: 0,
            loaderMessage: 'You are offline',
            loaderDetails: 'Please check your connection and try again.',
            postCategory: null,
            success: false,
            postCategories: null,
            actionText: 'Retry',
            action: '/'
        }
    }
}

export const postCategoryToAddLoader = async ({params}) => {

    try {

        const postCategoriesResult = await getPostParentCategories(1 , 50, 'name', 'asc', '')
        const postCategoriesResponse = await postCategoriesResult.json()
        if(!postCategoriesResponse.success){
            return {
                errorCode: postCategoriesResult.status,
                loaderMessage: 'Page Not Found',
                loaderDetails: postCategoriesResult.status === 404 ? 'The requested resource could not be found on this server. Please verify your request and try again.': postCategoriesResponse.message,
                postCategory: null,
                success: false,
                postCategories: null,
                actionText: 'Go To Home Page',
                action: '/'
            }
        }
        
        return {
            errorCode: 200,
            loaderMessage: 'OK',
            loaderDetails: postCategoriesResponse.message,
            postCategory: null,
            success: true,
            postCategories: postCategoriesResponse.data.postCategories,
            actionText: 'Success',
            action: ''
        }
    } catch (error) {
        return {
            errorCode: 0,
            loaderMessage: 'You are offline',
            loaderDetails: 'Please check your connection and try again.',
            postCategory: null,
            success: false,
            postCategories: null,
            actionText: 'Retry',
            action: '/'
        }
    }
}