import { SERVER_URL } from "../../utils/server/server";
import { mainFetcher } from "../../utils/services/service";

export const getInterests = async (resumePriority, search, publicResume, publicProfile, pageNo , pageSize, sortField, sortDir) => {    
    resumePriority = resumePriority !== null ? '&resumePriority=' + resumePriority : ''
    search = search ? '&search=' + search : ''
    publicResume = publicResume !== null ? '&publicResume=' + publicResume : ''
    publicProfile = publicProfile !== null ? '&publicProfile=' + publicProfile : ''

    const urlParams = 'pageNo=' + pageNo 
    +  '&pageSize=' + pageSize
    + '&sortField=' + sortField
    + '&sortDir=' + sortDir
    + resumePriority
    + search
    + publicResume
    + publicProfile

    return mainFetcher('get', {}, SERVER_URL + '/api/interest/?' + urlParams )
};

export const updateInterest = async (interest) => {
    return mainFetcher('put', interest, SERVER_URL + '/api/interest/')
};

export const createInterest = async (interest) => {
    return mainFetcher('post', interest, SERVER_URL + '/api/interest/')
};

export const getInterest = async (interestId) => {
    return mainFetcher('get', {}, SERVER_URL + '/api/interest/' + interestId)
};

export const deleteInterest = async (interestId) => {
    return mainFetcher('delete', {}, SERVER_URL + '/api/interest/' + interestId)
};

export const getInterestsByUserId = async (userId, resumePriority, search, pageNo , pageSize, sortField, sortDir) => {
    resumePriority = resumePriority !== null ? '&resumePriority=' + resumePriority : ''
    search = search ? '&search=' + search : ''

    const urlParams = 'pageNo=' + pageNo 
    +  '&pageSize=' + pageSize
    + '&sortField=' + sortField
    + '&sortDir=' + sortDir
    + resumePriority
    + search

    return mainFetcher('get', {}, SERVER_URL + '/api/interest/user/' + userId + '?' + urlParams )
};

export const deleteInterestsByUserId = async (userId) => {
    return mainFetcher('delete', {}, SERVER_URL + '/api/interest/' + userId)
};

export const countInterests = async () => {
    return mainFetcher('get', {}, SERVER_URL + '/api/interest/count')
};

export const countInterestsByUserId = async (userId) => {
    return mainFetcher('get', {}, SERVER_URL + '/api/interest/count-by-user-id/' + userId)
};