import { SERVER_URL } from "../../utils/server/server";
import { mainFetcher } from "../../utils/services/service";

export const getGuides = async (search, published, pageNo , pageSize, sortField, sortDir) => {

    published = published !== null ? '&published=' + published : ''
    search = search ? '&search=' + search : ''
    
    const urlParams = 'pageNo=' + pageNo 
    +  '&pageSize=' + pageSize
    + '&sortField=' + sortField
    + '&sortDir=' + sortDir
    + search
    + published

    return mainFetcher('get', {}, SERVER_URL + '/api/guide/?' + urlParams )
};

export const updateGuide = async (guide) => {
    return mainFetcher('put', guide, SERVER_URL + '/api/guide/')
};

export const createGuide = async (guide) => {
    return mainFetcher('post', guide, SERVER_URL + '/api/guide/')
};

export const getGuide = async (guideId, published) => {
    published = published !== null ? 'published=' + published : ''

    const urlParams =  published

    return mainFetcher('get', {}, SERVER_URL + '/api/guide/' + guideId + "?" + urlParams)
};

export const getGuideByGuideName = async (guideName, published) => {
    published = published !== null ? 'published=' + published : ''

    const urlParams =  published

    return mainFetcher('get', {}, SERVER_URL + '/api/guide/get-by-guide-name/' + guideName + "?" + urlParams)
};

export const deleteGuide = async (guideId) => {
    return mainFetcher('delete', {}, SERVER_URL + '/api/guide/' + guideId)
};

export const getGuidesCount = async () => {
    return mainFetcher('get', {}, SERVER_URL + '/api/guide/count' )
};