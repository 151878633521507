import { SERVER_URL } from "../../utils/server/server";
import { mainFetcher, mainFileFetcher } from "../../utils/services/service";

export const setPostImage = async (data , postId) => {
    return mainFileFetcher('put', data, SERVER_URL + '/api/post/set-post-image/' + postId)
};

export const getPosts = async (search, published, pageNo , pageSize, sortField, sortDir) => {

    published = published !== null ? '&published=' + published : ''
    
    const urlParams = 'pageNo=' + pageNo 
    +  '&pageSize=' + pageSize
    + '&sortField=' + sortField
    + '&sortDir=' + sortDir
    + '&search=' + search
    + published

    return mainFetcher('get', {}, SERVER_URL + '/api/post/?' + urlParams )
};

export const updatePost = async (post) => {
    return mainFetcher('put', post, SERVER_URL + '/api/post/')
};

export const createPost = async (post) => {
    return mainFetcher('post', post, SERVER_URL + '/api/post/')
};

export const getPost = async (postId, published) => {
    published = published !== null ? 'published=' + published : ''

    const urlParams =  published

    return mainFetcher('get', {}, SERVER_URL + '/api/post/' + postId + "?" + urlParams)
};

export const getPostByPostName = async (postName, published) => {
    published = published !== null ? 'published=' + published : ''

    const urlParams =  published

    return mainFetcher('get', {}, SERVER_URL + '/api/post/get-by-post-name/' + postName + "?" + urlParams)
};

export const deletePost = async (postId) => {
    return mainFetcher('delete', {}, SERVER_URL + '/api/post/' + postId)
};

export const getPostsByUserId = async (userId, search, published, pageNo , pageSize, sortField, sortDir) => {
    
    published = published !== null ? '&published=' + published : ''

    const urlParams = 'pageNo=' + pageNo 
    +  '&pageSize=' + pageSize
    + '&sortField=' + sortField
    + '&sortDir=' + sortDir
    + '&search=' + search
    + published

    return mainFetcher('get', {}, SERVER_URL + '/api/post/user/' + userId + '?' + urlParams )
};


export const getPostsByUserIdAndCategoryId = async (userId, categoryId, search, published, pageNo , pageSize, sortField, sortDir) => {
    
    published = published !== null ? '&published=' + published : ''

    const urlParams = 'pageNo=' + pageNo 
    +  '&pageSize=' + pageSize
    + '&sortField=' + sortField
    + '&sortDir=' + sortDir
    + '&search=' + search
    + published

    return mainFetcher('get', {}, SERVER_URL + '/api/post/user/' + userId + '/category/' + categoryId + '?' + urlParams )
};

export const getPostsByCategoryId = async ( categoryId, search, published, pageNo , pageSize, sortField, sortDir) => {
    
    published = published !== null ? '&published=' + published : ''

    const urlParams = 'pageNo=' + pageNo 
    +  '&pageSize=' + pageSize
    + '&sortField=' + sortField
    + '&sortDir=' + sortDir
    + '&search=' + search
    + published

    return mainFetcher('get', {}, SERVER_URL + '/api/post/category/' + categoryId + '?' + urlParams )
};

export const getPostsCount = async () => {
    return mainFetcher('get', {}, SERVER_URL + '/api/post/count' )
};

export const getPostsCountByUserId = async (userId) => {
    return mainFetcher('get', {}, SERVER_URL + '/api/post/count-by-user-id/' + userId )
};

export const getPostsCountByCategoryId = async (categoryId) => {
    return mainFetcher('get', {}, SERVER_URL + '/api/post/count-by-category-id/' + categoryId )
};


export const deletePostImage = async (postId) => {
    return mainFetcher('delete', {}, SERVER_URL + '/api/post/delete-post-image/' + postId)
};