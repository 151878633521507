import { SERVER_URL } from "../../utils/server/server";
import { mainFetcher } from "../../utils/services/service";

export const getEducations = async (resumePriority, search, publicResume, publicProfile, pageNo , pageSize, sortField, sortDir) => {    
    resumePriority = resumePriority !== null ? '&resumePriority=' + resumePriority : ''
    search = search ? '&search=' + search : ''
    publicResume = publicResume !== null ? '&publicResume=' + publicResume : ''
    publicProfile = publicProfile !== null ? '&publicProfile=' + publicProfile : ''

    const urlParams = 'pageNo=' + pageNo 
    +  '&pageSize=' + pageSize
    + '&sortField=' + sortField
    + '&sortDir=' + sortDir
    + resumePriority
    + search
    + publicResume
    + publicProfile

    return mainFetcher('get', {}, SERVER_URL + '/api/education/?' + urlParams )
};

export const updateEducation = async (education) => {
    return mainFetcher('put', education, SERVER_URL + '/api/education/')
};

export const createEducation = async (education) => {
    return mainFetcher('post', education, SERVER_URL + '/api/education/')
};

export const getEducation = async (educationId) => {
    return mainFetcher('get', {}, SERVER_URL + '/api/education/' + educationId)
};

export const deleteEducation = async (educationId) => {
    return mainFetcher('delete', {}, SERVER_URL + '/api/education/' + educationId)
};

export const getEducationsByUserId = async (userId, resumePriority, search, pageNo , pageSize, sortField, sortDir) => {
    resumePriority = resumePriority !== null ? '&resumePriority=' + resumePriority : ''
    search = search ? '&search=' + search : ''

    const urlParams = 'pageNo=' + pageNo 
    +  '&pageSize=' + pageSize
    + '&sortField=' + sortField
    + '&sortDir=' + sortDir
    + resumePriority
    + search

    return mainFetcher('get', {}, SERVER_URL + '/api/education/user/' + userId + '?' + urlParams )
};

export const deleteEducationsByUserId = async (userId) => {
    return mainFetcher('delete', {}, SERVER_URL + '/api/education/' + userId)
};

export const countEducation = async () => {
    return mainFetcher('get', {}, SERVER_URL + '/api/education/count')
};

export const countEducationByUserId = async (userId) => {
    return mainFetcher('get', {}, SERVER_URL + '/api/education/count-by-user-id/' + userId)
};