import { getCurrentUserProfile } from "../../../user/services/user.service"

export const currentUserProfileLoader =async ({params}) => {

    try {
        const result = await getCurrentUserProfile(params.username)
        const response = await result.json()
        if(!response.success){
            return {
                errorCode: result.status,
                message: 'Page Not Found',
                details: result.status === 404 ? 'The requested resource could not be found on this server. Please verify your request and try again.': response.message,
                currentUserProfile: null,
                success: false,
                actionText: 'Discover Profiles',
                action: '/discover'
            }
        }

        return {
            errorCode: 200,
            message: 'OK',
            details: response.message,
            currentUserProfile: response.data.currentUserProfile,
            success: true,
            actionText: 'Success',
            action: ''
        }
    } catch (error) {
        return {
            errorCode: 0,
            message: 'You are offline',
            details: 'Please check your connection and try again.',
            currentUserProfile: null,
            success: false,
            actionText: 'Retry',
            action: '/discover'
        }
    }
}