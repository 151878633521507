import { SERVER_URL } from "../../utils/server/server";
import { mainFetcher } from "../../utils/services/service";

export const getSkills = async (resumePriority, publicProfile, search, pageNo , pageSize, sortField, sortDir) => {  
    resumePriority = resumePriority !== null ? '&resumePriority=' + resumePriority : ''
    search = search ? '&search=' + search : ''
    publicProfile = publicProfile !== null ? '&publicProfile=' + publicProfile : ''
    
    const urlParams = 'pageNo=' + pageNo 
    +  '&pageSize=' + pageSize
    + '&sortField=' + sortField
    + '&sortDir=' + sortDir
    + resumePriority
    + search
    + publicProfile

    return mainFetcher('get', {}, SERVER_URL + '/api/skill/?' + urlParams )
};

export const updateSkill = async (skill) => {
    return mainFetcher('put', skill, SERVER_URL + '/api/skill/')
};

export const createSkill = async (skill) => {
    return mainFetcher('post', skill, SERVER_URL + '/api/skill/')
};

export const getSkill = async (skillId) => {
    return mainFetcher('get', {}, SERVER_URL + '/api/skill/' + skillId)
};

export const deleteSkill = async (skillId) => {
    return mainFetcher('delete', {}, SERVER_URL + '/api/skill/' + skillId)
};

export const getSkillsByUserId = async (userId, resumePriority, search, pageNo , pageSize, sortField, sortDir) => {
    resumePriority = resumePriority !== null ? '&resumePriority=' + resumePriority : ''
    search = search ? '&search=' + search : ''

    const urlParams = 'pageNo=' + pageNo 
    +  '&pageSize=' + pageSize
    + '&sortField=' + sortField
    + '&sortDir=' + sortDir
    + resumePriority
    + search

    return mainFetcher('get', {}, SERVER_URL + '/api/skill/user/' + userId + '?' + urlParams )
};

export const deleteSkillsByUserId = async (userId) => {
    return mainFetcher('delete', {}, SERVER_URL + '/api/skill/' + userId)
};

export const countSkills = async () => {
    return mainFetcher('get', {}, SERVER_URL + '/api/skill/count')
};

export const countSkillsByUserId = async (userId) => {
    return mainFetcher('get', {}, SERVER_URL + '/api/skill/count-by-user-id/' + userId)
};

export const getSkillTypeEnum = async () => {
    return mainFetcher('get', {}, SERVER_URL + '/api/skill/skill-type-enum/')
};