import { SERVER_URL } from "../../utils/server/server";
import { mainFetcher } from "../../utils/services/service";

export const getResumes = async (search, pageNo , pageSize, sortField, sortDir) => {   
    search = search ? '&search=' + search : ''
    
    const urlParams = 'pageNo=' + pageNo 
    +  '&pageSize=' + pageSize
    + '&sortField=' + sortField
    + '&sortDir=' + sortDir
    + search

    return mainFetcher('get', {}, SERVER_URL + '/api/resume/?' + urlParams )
};

export const updateResume = async (resume) => {
    return mainFetcher('put', resume, SERVER_URL + '/api/resume/')
};

export const createResume = async (resume) => {
    return mainFetcher('post', resume, SERVER_URL + '/api/resume/')
};

export const getResume = async (resumeId) => {
    return mainFetcher('get', {}, SERVER_URL + '/api/resume/' + resumeId)
};

export const deleteResume = async (resumeId) => {
    return mainFetcher('delete', {}, SERVER_URL + '/api/resume/' + resumeId)
};

export const getResumeByUserId = async (userId) => {
    return mainFetcher('get', {}, SERVER_URL + '/api/resume/user/' + userId )
};

export const deleteResumeByUserId = async (userId) => {
    return mainFetcher('delete', {}, SERVER_URL + '/api/resume/user/' + userId)
};

export const countResumes = async () => {
    return mainFetcher('get', {}, SERVER_URL + '/api/resume/count')
};