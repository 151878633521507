import { getProjectCategories } from "../../../projects/services/project-category.service"
import { getProject } from "../../../projects/services/project.service"

export const projectToEditLoader = async ({params}) => {

    try {
        const projectId = params.projectId

        const result = await getProject(projectId, null)
        const response = await result.json()
        if(!response.success){
            return {
                errorCode: result.status,
                loaderMessage: 'Page Not Found',
                loaderDetails: result.status === 404 ? 'The requested resource could not be found on this server. Please verify your request and try again.': response.message,
                project: null,
                success: false,
                projectCategories: null,
                actionText: 'Go To Home Page',
                action: '/'
            }
        }

        const projectCategoriesResult = await getProjectCategories(1 , 50, 'name', 'asc', '')
        const projectCategoriesResponse = await projectCategoriesResult.json()
        if(!projectCategoriesResponse.success){
            return {
                errorCode: projectCategoriesResult.status,
                loaderMessage: 'Page Not Found',
                loaderDetails: projectCategoriesResult.status === 404 ? 'The requested resource could not be found on this server. Please verify your request and try again.': projectCategoriesResponse.message,
                project: null,
                success: false,
                projectCategories: null,
                actionText: 'Go To Home Page',
                action: '/'
            }
        }

        return {
            errorCode: 200,
            loaderMessage: 'OK',
            loaderDetails: response.message,
            project: response.data.project,
            success: true,
            projectCategories: projectCategoriesResponse.data.projectCategories,
            actionText: 'Success',
            action: ''
        }
        
    } catch (error) {
        return {
            errorCode: 0,
            loaderMessage: 'You are offline',
            loaderDetails: 'Please check your connection and try again.',
            project: null,
            success: false,
            projectCategories: null,
            actionText: 'Retry',
            action: '/'
        }
    }
}

export const projectToAddLoader = async ({params}) => {

    try {

        const projectCategoriesResult = await getProjectCategories(1 , 50, 'name', 'asc', '')
        const projectCategoriesResponse = await projectCategoriesResult.json()
        if(!projectCategoriesResponse.success){
            return {
                errorCode: projectCategoriesResult.status,
                loaderMessage: 'Page Not Found',
                loaderDetails: projectCategoriesResult.status === 404 ? 'The requested resource could not be found on this server. Please verify your request and try again.': projectCategoriesResponse.message,
                project: null,
                success: false,
                projectCategories: null,
                actionText: 'Go To Home Page',
                action: '/'
            }
        }
        
        return {
            errorCode: 200,
            loaderMessage: 'OK',
            loaderDetails: projectCategoriesResponse.message,
            project: null,
            success: true,
            projectCategories: projectCategoriesResponse.data.projectCategories,
            actionText: 'Success',
            action: ''
        }
    } catch (error) {
        return {
            errorCode: 0,
            loaderMessage: 'You are offline',
            loaderDetails: 'Please check your connection and try again.',
            project: null,
            success: false,
            projectCategories: null,
            actionText: 'Retry',
            action: '/'
        }
    }
}