import { AUTH_ACTION_TYPES } from "../types/auth.types"

const AUTH_INITIAL_STATE = {
    loggedInUser: null,
    isLoading: false,
    error: null,
    resetPasswordToken: null
}
export const authReducer = (state = AUTH_INITIAL_STATE, action ) => {
    const {type, payload} = action

    switch(type){
        case AUTH_ACTION_TYPES.SET_LOGGED_IN_USER:
            return {...state ,loggedInUser: payload, isLoading: false}
        case AUTH_ACTION_TYPES.SET_RESET_PASSWORD_TOKEN:
            return {...state ,resetPasswordToken: payload}
        case AUTH_ACTION_TYPES.SIGN_OUT_SUCCESS:
            return {...state ,loggedInUser: null}
        case AUTH_ACTION_TYPES.EMAIL_SIGN_IN_START:
            return {...state ,loggedInUser: true}
        case AUTH_ACTION_TYPES.SIGN_IN_FAILED:
        case AUTH_ACTION_TYPES.SIGN_UP_FAILED:
        case AUTH_ACTION_TYPES.SIGN_OUT_FAILED:
            return {...state, error: payload, isLoading: false}
        default:
            return state
    }
}