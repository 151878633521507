import { CONTENT_HEADER_ACTION_TYPES } from "../types/content-header.types"

const CONTENT_HEADER_INITIAL_STATE = {
    title: 'Dashboard',
    headers:
    [
        {
            active: true,
            name: 'Dashboard',
            to: '/my/'
        }
    ]
}

export const contentHeaderReducer = (state = CONTENT_HEADER_INITIAL_STATE, action) => {
    const {type, payload} = action

    switch (type) {
        case CONTENT_HEADER_ACTION_TYPES.SET_CONTENT_HEADER_TITLE:
            return {...state, title: payload}
        case CONTENT_HEADER_ACTION_TYPES.SET_CONTENT_HEADER_HEADERS:
            return {...state, headers: payload}
        default:
            return state;
    }
}