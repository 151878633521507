import { getSalesGeneratedByProjectId } from "../../../projects/services/project-sales-generated.service"
import { getProject } from "../../../projects/services/project.service"


export const salesGeneratedLoader = async ({params}) => {

    try {
        const projectId = params.projectId

        const salesGeneratedResult = await getSalesGeneratedByProjectId(projectId)
        const salesGeneratedResponse = await salesGeneratedResult.json()
        if(salesGeneratedResponse.success){
            return {
                errorCode: 200,
                loaderMessage: 'OK',
                loaderDetails: salesGeneratedResponse.message,
                salesGeneratedProject: salesGeneratedResponse.data.salesGenerated.project,
                success: true,
                salesGenerated: salesGeneratedResponse.data.salesGenerated,
                actionText: 'Success',
                action: '/'
            }
        }else{
            const result = await getProject(projectId, null)
            const response = await result.json()
            if(response.success){
                return {
                    errorCode: 200,
                    loaderMessage: 'OK',
                    loaderDetails: response.message,
                    salesGeneratedProject: response.data.project,
                    success: true,
                    salesGenerated: null,
                    actionText: 'Success',
                    action: ''
                }
            }else{
                return {
                    errorCode: result.status,
                    loaderMessage: 'Page Not Found',
                    loaderDetails: result.status === 404 ? 'The requested resource could not be found on this server. Please verify your request and try again.': response.message,
                    salesGeneratedProject: null,
                    success: false,
                    salesGenerated: null,
                    actionText: 'Go To Home Page',
                    action: '/'
                }
            }
        }
        
    } catch (error) {
        return {
            errorCode: 0,
            loaderMessage: 'You are offline',
            loaderDetails: 'Please check your connection and try again.',
            salesGeneratedProject: null,
            success: false,
            salesGenerated: null,
            actionText: 'Retry',
            action: '/'
        }
    }
}