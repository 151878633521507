import { SERVER_URL } from "../../utils/server/server";
import { mainFetcher } from "../../utils/services/service";


export const getOverallCosts = async (pageNo , pageSize, sortField, sortDir) => {

    const urlParams = 'pageNo=' + pageNo 
    +  '&pageSize=' + pageSize
    + '&sortField=' + sortField
    + '&sortDir=' + sortDir

    return mainFetcher('get', {}, SERVER_URL + '/api/overall-costs/?' + urlParams )
};

export const getOverallCostsByUserId = async (userId, pageNo , pageSize, sortField, sortDir) => {    
    const urlParams = 'pageNo=' + pageNo 
    +  '&pageSize=' + pageSize
    + '&sortField=' + sortField
    + '&sortDir=' + sortDir

    return mainFetcher('get', {}, SERVER_URL + '/api/overall-costs/user/' + userId + '?' + urlParams )
};

export const updateOverallCost = async (overallCost) => {
    return mainFetcher('put', overallCost, SERVER_URL + '/api/overall-costs/')
};

export const createOverallCost = async (overallCost) => {
    return mainFetcher('post', overallCost, SERVER_URL + '/api/overall-costs/')
};

export const getOverallCost = async (overallCostId) => {
    return mainFetcher('get', {}, SERVER_URL + '/api/overall-costs/' + overallCostId)
};

export const deleteOverallCost = async (overallCostId) => {
    return mainFetcher('delete', {}, SERVER_URL + '/api/overall-costs/' + overallCostId)
};

export const getOverallCostByProjectId = async (projectId) => {

    return mainFetcher('get', {}, SERVER_URL + '/api/overall-costs/project/' + projectId )
};

export const deleteOverallCostByProjectId = async (projectId) => {
    return mainFetcher('delete', {}, SERVER_URL + '/api/overall-costs/project/' + projectId)
};

export const getOverallCostsCount = async () => {
    return mainFetcher('get', {}, SERVER_URL + '/api/overall-costs/count')
};

export const getOverallCostsCountByUserId = async (userId) => {
    return mainFetcher('get', {}, SERVER_URL + '/api/overall-costs/count-by-user-id/' + userId)
};