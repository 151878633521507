import { getFeatured } from "../../../projects/services/featured-project.service"
import { getProject } from "../../../projects/services/project.service"

export const featuredToEditLoader = async ({params}) => {

    try {
        const featuredId = params.featuredId

        const result = await getFeatured(featuredId, null)
        const response = await result.json()
        if(!response.success){
            return {
                errorCode: result.status,
                loaderDetails: result.status === 404 ? 'The requested resource could not be found on this server. Please verify your request and try again.': response.message,
                featuredProject: null,
                success: false,
                featured: null,
                actionText: 'Go To Home Page',
                action: '/'
            }
        }

        return {
            errorCode: 200,
            loaderMessage: 'OK',
            loaderDetails: response.message,
            featuredProject: response.data.featured.project,
            success: true,
            featured: response.data.featured,
            actionText: 'Success',
            action: ''
        }
        
    } catch (error) {
        return {
            errorCode: 0,
            loaderMessage: 'You are offline',
            loaderDetails: 'Please check your connection and try again.',
            featuredProject: null,
            success: false,
            featured: null,
            actionText: 'Retry',
            action: '/'
        }
    }
}

export const featuredToAddLoader = async ({params}) => {

    try {
        const projectId = params.projectId

        const projectResult = await getProject(projectId, null)
        const projectResponse = await projectResult.json()
        if(!projectResponse.success){
            return {
                errorCode: projectResponse.status,
                loaderMessage: 'Page Not Found',
                loaderDetails: projectResult.status === 404 ? 'The requested resource could not be found on this server. Please verify your request and try again.': projectResponse.message,
                featuredProject: null,
                success: false,
                featured: null
            }
        }
        
        return {
            errorCode: 200,
            loaderMessage: 'OK',
            loaderDetails: projectResponse.message,
            featuredProject: projectResponse.data.project,
            success: true,
            featured: null,
            actionText: 'Success',
            action: ''
        }
    } catch (error) {
        return {
            errorCode: 0,
            loaderMessage: 'You are offline',
            loaderDetails: 'Please check your connection and try again.',
            featuredProject: null,
            success: false,
            featured: null,
            actionText: 'Retry',
            action: '/'
        }
    }
}