import { getEfficiencyByProjectId } from "../../../projects/services/project-efficiency.service"
import { getProject } from "../../../projects/services/project.service"

export const efficiencyLoader = async ({params}) => {

    try {
        const projectId = params.projectId

        const efficiencyResult = await getEfficiencyByProjectId(projectId)
        const efficiencyResponse = await efficiencyResult.json()
        if(efficiencyResponse.success){
            return {
                errorCode: 200,
                loaderMessage: 'OK',
                loaderDetails: efficiencyResponse.message,
                efficiencyProject: efficiencyResponse.data.efficiency.project,
                success: true,
                efficiency: efficiencyResponse.data.efficiency,
                actionText: 'Success',
                action: '/'
            }
        }else{
            const result = await getProject(projectId, null)
            const response = await result.json()
            if(response.success){
                return {
                    errorCode: 200,
                    loaderMessage: 'OK',
                    loaderDetails: response.message,
                    efficiencyProject: response.data.project,
                    success: true,
                    efficiency: null,
                    actionText: 'Success',
                    action: ''
                }
            }else{
                return {
                    errorCode: result.status,
                    loaderDetails: result.status === 404 ? 'The requested resource could not be found on this server. Please verify your request and try again.': response.message,
                    efficiencyProject: null,
                    success: false,
                    efficiency: null,
                    actionText: 'Go To Home Page',
                    action: '/'
                }
            }
        }
        
    } catch (error) {
        return {
            errorCode: 0,
            loaderMessage: 'You are offline',
            loaderDetails: 'Please check your connection and try again.',
            efficiencyProject: null,
            success: false,
            efficiency: null,
            actionText: 'Retry',
            action: '/'
        }
    }
}