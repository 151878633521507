import { SERVER_URL } from "../../utils/server/server";
import { mainFetcher } from "../../utils/services/service";

export const getPrivileges = async (search, pageNo , pageSize, sortField, sortDir) => {
    
    const urlParams = 'pageNo=' + pageNo 
    +  '&pageSize=' + pageSize
    + '&sortField=' + sortField
    + '&sortDir=' + sortDir
    + '&search=' + search
    return mainFetcher('get', {}, SERVER_URL + '/api/privileges/?' + urlParams )
};

export const createPrivilege = async (privilege) => {
    return mainFetcher('post', privilege, SERVER_URL + '/api/privileges/')
};

export const updatePrivilege = async (privilege) => {
    return mainFetcher('put', privilege, SERVER_URL + '/api/privileges/')
};

export const getPrivilegeById = async (privilegeId) => {
    return mainFetcher('get', {}, SERVER_URL + '/api/privileges/' + privilegeId)
};

export const deletePrivilegeById = async (privilegeId) => {
    return mainFetcher('delete', {}, SERVER_URL + '/api/privileges/' + privilegeId)
};

export const getPrivilegeCount = async () => {
    return mainFetcher('get', {}, SERVER_URL + '/api/privileges/count')
};
