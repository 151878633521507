import { compose, createStore, applyMiddleware } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { logger } from "redux-logger";

import { rootReducer } from "./root-reducer";

const middlewares = [process.env.NODE_ENV !== 'production' && logger].filter(Boolean)

const persistConfig = {
    key: 'root',
    storage,
    blacklist: [],
    whitelist: ['auth', 'currentUserProfile', 'errors', 'bedubyte', 'contentHeader', 'management']
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const composedEnhancer = (process.env.NODE_ENV !== 'production' && window && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose

const composedEnhancers = composedEnhancer(applyMiddleware(...middlewares))

export const store = createStore(persistedReducer, undefined, composedEnhancers)

export const persistor = persistStore(store) 