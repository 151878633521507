import { getPromo, getPromoTypeEnum } from "../../../promos/services/promo-service"

export const promoToEditLoader = async ({params}) => {

    try {
        const promoId = params.promoId

        const result = await getPromo(promoId)
        const response = await result.json()
        if(!response.success){
            return {
                errorCode: result.status,
                loaderMessage: 'Page Not Found',
                loaderDetails: result.status === 404 ? 'The requested resource could not be found on this server. Please verify your request and try again.': response.message,
                promo: null,
                promoTypeEnum: null,
                success: false,
                actionText: 'Go To Home Page',
                action: '/'
            }
        }

        const promoTypeEnumResult = await getPromoTypeEnum()
        const promoTypeEnumResponse = await promoTypeEnumResult.json()
        if(!promoTypeEnumResponse.success){
            return {
                errorCode: promoTypeEnumResult.status,
                loaderMessage: 'Page Not Found',
                loaderDetails: result.status === 404 ? 'The requested resource could not be found on this server. Please verify your request and try again.': response.message,
                promo: null,
                promoTypeEnum: null,
                success: false,
                actionText: 'Go To Home Page',
                action: '/'
            }
        }

        return {
            errorCode: 200,
            loaderMessage: 'OK',
            loaderDetails: response.message,
            promo: response.data.promo,
            promoTypeEnum: promoTypeEnumResponse.data.promoTypeEnum,
            success: true,
            actionText: 'Success',
            action: ''
        }
        
    } catch (error) {
        return {
            errorCode: 0,
            loaderMessage: 'You are offline',
            loaderDetails: 'Please check your connection and try again.',
            promo: null,
            success: false,
            actionText: 'Retry',
            action: '/'
        }
    }
}

export const promoToAddLoader = async ({params}) => {
    try {
        const promoTypeEnumResult = await getPromoTypeEnum()
        const promoTypeEnumResponse = await promoTypeEnumResult.json()
        if(!promoTypeEnumResponse.success){
            return {
                errorCode: promoTypeEnumResult.status,
                loaderMessage: 'Page Not Found',
                loaderDetails: promoTypeEnumResult.status === 404 ? 'The requested resource could not be found on this server. Please verify your request and try again.': promoTypeEnumResponse.message,
                promo: null,
                promoTypeEnum: null,
                success: false,
                actionText: 'Go To Home Page',
                action: '/'
            }
        }

        return {
            errorCode: 200,
            loaderMessage: 'OK',
            loaderDetails: promoTypeEnumResponse.message,
            promo: null,
            promoTypeEnum: promoTypeEnumResponse.data.promoTypeEnum,
            success: true,
            actionText: 'Success',
            action: ''
        }
        
    } catch (error) {
        return {
            errorCode: 0,
            loaderMessage: 'You are offline',
            loaderDetails: 'Please check your connection and try again.',
            promo: null,
            promoTypeEnum: null,
            success: false,
            actionText: 'Retry',
            action: '/'
        }
    }
}