export const AUTH_ACTION_TYPES = {
    SET_LOGGED_IN_USER : 'SET_LOGGED_IN_USER',
    SET_RESET_PASSWORD_TOKEN : 'SET_RESET_PASSWORD_TOKEN',
    CHECK_USER_SESSION:  'CHECK_USER_SESSION',
    GOOGLE_SIGN_IN_START: 'GOOGLE_SIGN_IN_START',
    GITHUB_SIGN_IN_START: 'GITHUB_SIGN_IN_START',
    FACEBOOK_SIGN_IN_START: 'FACEBOOK_SIGN_IN_START',
    EMAIL_SIGN_IN_START: 'EMAIL_SIGN_IN_START',
    SIGN_IN_SUCCESS: 'SIGN_IN_SUCCESS',
    SIGN_IN_FAILED: 'SIGN_IN_FAILED',
    SIGN_UP_START: 'SIGN_UP_START',
    SIGN_UP_SUCCESS: 'SIGN_UP_SUCCESS',
    SIGN_UP_FAILED: 'SIGN_UP_FAILED',
    SIGN_OUT_START: 'SIGN_OUT_START',
    SIGN_OUT_SUCCESS: 'SIGN_OUT_SUCCESS',
    SIGN_OUT_FAILED: 'SIGN_OUT_FAILED',
}

export const AUTH_TOKEN_TYPES = {
    PASSWORD_RESET_TOKEN: "password-reset-token",
    EMAIL_VERIFICATION_TOKEN: "email-verification-token",
}