import { getUserById } from "../../../user/services/user.service"
import { getRoles, getRolesByUserId } from "../../../user/services/role.service";


export const userToEditLoader = async ({params}) => {

    try {
        const userId = params.userId

        const result = await getUserById(userId)
        const response = await result.json()
        if(!response.success){
            return {
                errorCode: result.status,
                loaderDetails: result.status === 404 ? 'The requested resource could not be found on this server. Please verify your request and try again.': response.message,
                user: null,
                userRoles: null,
                allRoles: null,
                success: false,
                actionText: 'Go To Home Page',
                action: '/'
            }
        }

        const rolesResult = await getRoles('', 1, 200, 'name', 'asc')
        const rolesResponse = await rolesResult.json()
        if(!rolesResponse.success){
            return {
                errorCode: rolesResult.status,
                loaderDetails: rolesResult.status === 404 ? 'The requested resource could not be found on this server. Please verify your request and try again.': rolesResponse.message,
                user: null,
                userRoles: null,
                allRoles: null,
                success: false,
                actionText: 'Go To Home Page',
                action: '/'
            }
        }

        const userRolesResult = await getRolesByUserId(userId)
        const userRolesResponse = await userRolesResult.json()
        if(!userRolesResponse.success){
            return {
                errorCode: userRolesResult.status,
                loaderDetails: userRolesResult.status === 404 ? 'The requested resource could not be found on this server. Please verify your request and try again.': userRolesResponse.message,
                user: null,
                userRoles: null,
                allRoles: null,
                success: false,
                actionText: 'Go To Home Page',
                action: '/'
            }
        }

        return {
            errorCode: 200,
            loaderMessage: 'OK',
            loaderDetails: response.message,
            user: response.data.user,
            userRoles: userRolesResponse.data.roles,
            allRoles: rolesResponse.data.roles,
            success: true,
        }
        
    } catch (error) {
        return {
            errorCode: 0,
            loaderMessage: 'You are offline',
            loaderDetails: 'Please check your connection and try again.',
            user: null,
            userRoles: null,
            allRoles: null,
            success: false,
            actionText: 'Retry',
            action: '/'
        }
    }
}

export const userToAddLoader = async ({params}) => {
    try {
        const rolesResult = await getRoles('', 1, 200, 'name', 'asc')
        const rolesResponse = await rolesResult.json()
        if(!rolesResponse.success){
            return {
                errorCode: rolesResult.status,
                loaderMessage: 'Page Not Found',
                loaderDetails: rolesResult.status === 404 ? 'The requested resource could not be found on this server. Please verify your request and try again.': rolesResponse.message,
                user: null,
                userRoles: null,
                allRoles: null,
                success: false,
                actionText: 'Go To Home Page',
                action: '/'
            }
        }

        return {
            errorCode: 200,
            loaderMessage: 'OK',
            loaderDetails: rolesResponse.message,
            user: null,
            userRoles: [],
            allRoles: rolesResponse.data.roles,
            success: true,
            actionText: 'Success',
            action: ''
        }
        
    } catch (error) {
        return {
            errorCode: 0,
            loaderMessage: 'You are offline',
            loaderDetails: 'Please check your connection and try again.',
            user: null,
            userRoles: null,
            allRoles: null,
            success: false,
            actionText: 'Retry',
            action: '/'
        }
    }
}