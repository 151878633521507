import { getEducation } from "../../../educations/services/education.service"

export const educationToEditLoader = async ({params}) => {
    try {
        const educationId = params.educationId

        const result = await getEducation(educationId)
        const response = await result.json()
        if(!response.success){
            return {
                errorCode: result.status,
                loaderMessage: 'Page Not Found',
                loaderDetails: result.status === 404 ? 'The requested resource could not be found on this server. Please verify your request and try again.': response.message,
                education: null,
                success: false,
                actionText: 'Go To Home Page',
                action: '/'
            }
        }

        return {
            errorCode: 200,
            loaderMessage: 'OK',
            loaderDetails: response.message,
            education: response.data.education,
            success: true,
            actionText: 'Success',
            action: ''
        }
        
    } catch (error) {
        return {
            errorCode: 0,
            loaderMessage: 'You are offline',
            loaderDetails: 'Please check your connection and try again.',
            details: '',
            education: null,
            success: false,
            actionText: 'Retry',
            action: '/'
        }
    }
}

export const educationToAddLoader = async ({params}) => {
    return {
        errorCode: 200,
        loaderMessage: '',
        loaderDetails: '',
        education: null,
        success: true,
        actionText: 'Success',
        action: '/'
    }
}