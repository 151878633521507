import { MANAGEMENT_ACTION_TYPES } from "../types/management.types";

const MANAGEMENT_INITIAL_STATE = {
    search: ''
}

export const managementReducer = (state= MANAGEMENT_INITIAL_STATE, action) => {
    const {type, payload} = action

    switch (type) {
        case MANAGEMENT_ACTION_TYPES.SET_MANAGEMENT_SEARCH_INPUT_VALUE:
            return {...state, search: payload}
        default:
            return state;
    }
}