import { SERVER_URL } from "../../utils/server/server";
import { mainFetcher } from "../../utils/services/service";


export const getReferees = async ( pageNo , pageSize, sortField, sortDir, search) => {   
    search = search ? '&search=' + search : ''

    const urlParams = 'pageNo=' + pageNo 
    +  '&pageSize=' + pageSize
    + '&sortField=' + sortField
    + '&sortDir=' + sortDir
    + search

    return mainFetcher('get', {}, SERVER_URL + '/api/referee/?' + urlParams )
};

export const updateReferee = async (referee) => {
    return mainFetcher('put', referee, SERVER_URL + '/api/referee/')
};

export const createReferee = async (referee) => {
    return mainFetcher('post', referee, SERVER_URL + '/api/referee/')
};

export const getReferee = async (refereeId) => {
    return mainFetcher('get', {}, SERVER_URL + '/api/referee/' + refereeId)
};

export const deleteReferee = async (refereeId) => {
    return mainFetcher('delete', {}, SERVER_URL + '/api/referee/' + refereeId)
};

export const getRefereesByUserId = async (userId, pageNo , pageSize, sortField, sortDir, search, resumePriority) => {
    search = search ? '&search=' + search : ''
    resumePriority = resumePriority ? '&resumePriority=' + resumePriority : ''

    const urlParams = 'pageNo=' + pageNo 
    +  '&pageSize=' + pageSize
    + '&sortField=' + sortField
    + '&sortDir=' + sortDir
    + search
    + resumePriority

    return mainFetcher('get', {}, SERVER_URL + '/api/referee/user/' + userId + '?' + urlParams )
};

export const deleteRefereesByUserId = async (userId) => {
    return mainFetcher('delete', {}, SERVER_URL + '/api/referee/user/' + userId)
};

export const countReferees = async () => {
    return mainFetcher('get', {}, SERVER_URL + '/api/referee/count')
};

export const countRefereesByUserId = async (userId) => {
    return mainFetcher('get', {}, SERVER_URL + '/api/referee/count-by-user-id/' + userId)
};