import { SERVER_URL } from "../utils/server/server";
import { mainFetcher, mainFileFetcher } from "../utils/services/service";

export const createFile = async (data ) => {
    return mainFileFetcher('post', data, SERVER_URL + '/api/files/')
};

export const updateFile = async (data ) => {
    return mainFileFetcher('put', data, SERVER_URL + '/api/files/')
};

export const getFiles = async (search,  pageNo , pageSize, sortField, sortDir) => {

    
    const urlParams = 'pageNo=' + pageNo 
    +  '&pageSize=' + pageSize
    + '&sortField=' + sortField
    + '&sortDir=' + sortDir
    + '&search=' + search

    return mainFetcher('get', {}, SERVER_URL + '/api/files/?' + urlParams )
};

export const getFile = async (fileId) => {
    return mainFetcher('get', {}, SERVER_URL + '/api/files/' + fileId)
};

export const deleteFile = async (fileId) => {
    return mainFetcher('delete', {}, SERVER_URL + '/api/files/' + fileId)
};

export const getFilesByUserId = async (userId, search, pageNo , pageSize, sortField, sortDir) => {
    const urlParams = 'pageNo=' + pageNo 
    +  '&pageSize=' + pageSize
    + '&sortField=' + sortField
    + '&sortDir=' + sortDir
    + '&search=' + search
    return mainFetcher('get', {}, SERVER_URL + '/api/files/user/' + userId + '?' + urlParams )
};

export const getFilesCount = async () => {
    return mainFetcher('get', {}, SERVER_URL + '/api/files/count' )
};

export const getFilesCountByUserId = async (userId) => {
    return mainFetcher('get', {}, SERVER_URL + '/api/files/count-by-user-id/' + userId )
};