import { SERVER_URL } from "../../utils/server/server";
import { mainFetcher } from "../../utils/services/service";

export const getCertifications = async (resumePriority, search, publicResume, publicProfile, pageNo , pageSize, sortField, sortDir) => {
    resumePriority = resumePriority !== null ? '&resumePriority=' + resumePriority : ''
    search = search ? '&search=' + search : ''
    publicResume = publicResume !== null ? '&publicResume=' + publicResume : ''
    publicProfile = publicProfile !== null ? '&publicProfile=' + publicProfile : ''

    const urlParams = 'pageNo=' + pageNo 
    +  '&pageSize=' + pageSize
    + '&sortField=' + sortField
    + '&sortDir=' + sortDir
    + resumePriority
    + search
    + publicResume
    + publicProfile

    return mainFetcher('get', {}, SERVER_URL + '/api/certification/?' + urlParams )
};

export const updateCertification = async (certification) => {
    return mainFetcher('put', certification, SERVER_URL + '/api/certification/')
};

export const createCertification = async (certification) => {
    return mainFetcher('post', certification, SERVER_URL + '/api/certification/')
};

export const getCertification = async (certificationId) => {
    return mainFetcher('get', {}, SERVER_URL + '/api/certification/' + certificationId)
};

export const deleteCertification = async (certificationId) => {
    return mainFetcher('delete', {}, SERVER_URL + '/api/certification/' + certificationId)
};

export const getCertificationsByUserId = async (userId, resumePriority, search, pageNo , pageSize, sortField, sortDir) => {
    resumePriority = resumePriority !== null ? '&resumePriority=' + resumePriority : ''
    search = search ? '&search=' + search : ''

    const urlParams = 'pageNo=' + pageNo 
    +  '&pageSize=' + pageSize
    + '&sortField=' + sortField
    + '&sortDir=' + sortDir
    + resumePriority
    + search

    return mainFetcher('get', {}, SERVER_URL + '/api/certification/user/' + userId + '?' + urlParams )
};

export const deleteCertificationsByUserId = async (userId) => {
    return mainFetcher('delete', {}, SERVER_URL + '/api/certification/' + userId)
};

export const countCertifications = async () => {
    return mainFetcher('get', {}, SERVER_URL + '/api/certification/count')
};

export const countCertificationsByUserId = async (userId) => {
    return mainFetcher('get', {}, SERVER_URL + '/api/certification/count-by-user-id/' + userId)
};