import { SERVER_URL } from "../../utils/server/server";
import { mainFetcher } from "../../utils/services/service";

export const getLanguages = async (resumePriority, search, publicResume, publicProfile, pageNo , pageSize, sortField, sortDir) => {   
    resumePriority = resumePriority !== null ? '&resumePriority=' + resumePriority : ''
    search = search ? '&search=' + search : ''
    publicResume = publicResume !== null ? '&publicResume=' + publicResume : ''
    publicProfile = publicProfile !== null ? '&publicProfile=' + publicProfile : ''
    
    const urlParams = 'pageNo=' + pageNo 
    +  '&pageSize=' + pageSize
    + '&sortField=' + sortField
    + '&sortDir=' + sortDir
    + resumePriority
    + search
    + publicResume
    + publicProfile

    return mainFetcher('get', {}, SERVER_URL + '/api/language/?' + urlParams )
};

export const updateLanguage = async (language) => {
    return mainFetcher('put', language, SERVER_URL + '/api/language/')
};

export const createLanguage = async (language) => {
    return mainFetcher('post', language, SERVER_URL + '/api/language/')
};

export const getLanguage = async (languageId) => {
    return mainFetcher('get', {}, SERVER_URL + '/api/language/' + languageId)
};

export const deleteLanguage = async (languageId) => {
    return mainFetcher('delete', {}, SERVER_URL + '/api/language/' + languageId)
};

export const getLanguagesByUserId = async (userId, resumePriority, search, pageNo , pageSize, sortField, sortDir) => {
    resumePriority = resumePriority !== null ? '&resumePriority=' + resumePriority : ''
    search = search ? '&search=' + search : ''

    const urlParams = 'pageNo=' + pageNo 
    +  '&pageSize=' + pageSize
    + '&sortField=' + sortField
    + '&sortDir=' + sortDir
    + resumePriority
    + search

    return mainFetcher('get', {}, SERVER_URL + '/api/language/user/' + userId + '?' + urlParams )
};

export const deleteLanguagesByUserId = async (userId) => {
    return mainFetcher('delete', {}, SERVER_URL + '/api/language/user/' + userId)
};

export const countLanguages = async () => {
    return mainFetcher('get', {}, SERVER_URL + '/api/language/count')
};

export const countLanguagesByUserId = async (userId) => {
    return mainFetcher('get', {}, SERVER_URL + '/api/language/count-by-user-id/' + userId)
};