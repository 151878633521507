import { getOverallCostByProjectId } from "../../../projects/services/project-overall-cost.service"
import { getProject } from "../../../projects/services/project.service"

export const overallCostLoader = async ({params}) => {

    try {
        const projectId = params.projectId

        const overallCostResult = await getOverallCostByProjectId(projectId)
        const overallCostResponse = await overallCostResult.json()
        if(overallCostResponse.success){
            return {
                errorCode: 200,
                loaderMessage: 'OK',
                loaderDetails: overallCostResponse.message,
                overallCostProject: overallCostResponse.data.overallCost.project,
                success: true,
                overallCost: overallCostResponse.data.overallCost,
                actionText: 'Success',
                action: '/'
            }
        }else{
            const result = await getProject(projectId, null)
            const response = await result.json()
            if(response.success){
                return {
                    errorCode: 200,
                    loaderMessage: 'OK',
                    loaderDetails: response.message,
                    overallCostProject: response.data.project,
                    success: true,
                    overallCost: null,
                    actionText: 'Success',
                    action: ''
                }
            }else{
                return {
                    errorCode: result.status,
                    loaderMessage: 'Page Not Found',
                    loaderDetails: result.status === 404 ? 'The requested resource could not be found on this server. Please verify your request and try again.': response.message,
                    overallCostProject: null,
                    success: false,
                    overallCost: null,
                    actionText: 'Go To Home Page',
                    action: '/'
                }
            }
        }
        
    } catch (error) {
        console.error(error);
        return {
            errorCode: 0,
            loaderMessage: 'You are offline',
            loaderDetails: 'Please check your connection and try again.',
            overallCostProject: null,
            success: false,
            overallCost: null,
            actionText: 'Retry',
            action: '/'
        }
    }
}