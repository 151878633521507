import { getGuideByGuideName } from "../../../guides/services/guide.service"

export const guideLoader = async ({params}) => {

    try {
        const guideName = params.guideName

        const result = await getGuideByGuideName(guideName, true)
        const response = await result.json()
        if(!response.success){
            return {
                errorCode: result.status,
                message: 'Page Not Found',
                details: result.status === 404 ? 'The requested resource could not be found on this server. Please verify your request and try again.': response.message,
                guide: null,
                success: false,
                actionText: 'View Guides',
                action: '/getting-started'
            }
        }

        return {
            errorCode: 200,
            message: 'OK',
            details: response.message,
            guide: response.data.guide,
            success: true,
            actionText: 'Success',
            action: ''
        }
        
    } catch (error) {
        return {
            errorCode: 0,
            message: 'You are offline',
            details: 'Please check your connection and try again.',
            guide: null,
            success: false,
            actionText: 'Retry',
            action: '/getting-started'
        }
    }
}