import { SERVER_URL } from "../../utils/server/server";
import { mainFetcher } from "../../utils/services/service";

export const getProfiles = async (search, pageNo , pageSize, sortField, sortDir) => {   
    search = search ? '&search=' + search : ''
    
    const urlParams = 'pageNo=' + pageNo 
    +  '&pageSize=' + pageSize
    + '&sortField=' + sortField
    + '&sortDir=' + sortDir
    + search

    return mainFetcher('get', {}, SERVER_URL + '/api/profile/?' + urlParams )
};

export const updateProfile = async (profile) => {
    return mainFetcher('put', profile, SERVER_URL + '/api/profile/')
};

export const createProfile = async (profile) => {
    return mainFetcher('post', profile, SERVER_URL + '/api/profile/')
};

export const getProfile = async (profileId) => {
    return mainFetcher('get', {}, SERVER_URL + '/api/profile/' + profileId)
};

export const deleteProfile = async (profileId) => {
    return mainFetcher('delete', {}, SERVER_URL + '/api/profile/' + profileId)
};

export const getProfileByUserId = async (userId) => {
    return mainFetcher('get', {}, SERVER_URL + '/api/profile/user/' + userId )
};

export const deleteProfileyUserId = async (userId) => {
    return mainFetcher('delete', {}, SERVER_URL + '/api/profile/user/' + userId)
};

export const countProfiles = async () => {
    return mainFetcher('get', {}, SERVER_URL + '/api/profile/count')
};