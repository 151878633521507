export const authHeader = async () => {
    const store = await JSON.parse(localStorage.getItem('persist:root'));
    const auth = await JSON.parse(store.auth);

    if (auth.loggedInUser && auth.loggedInUser.token) {
        return 'Bearer ' + auth.loggedInUser.token;
    } else {
        return '';
    }
}

export const mainFetcher = async (method, data, url, contentType = 'application/json') =>{
    if(method === 'get'){
        return fetch( url, {
            method: method,
            mode: "cors",
            headers: {
                'Content-Type': contentType,
                Authorization: await authHeader()
            },
        })
    }
    
    return fetch( url, {
        method: method,
        mode: "cors",
        headers: {
            'Content-Type': contentType,
            Authorization: await authHeader() 
        },
        body: JSON.stringify(data),
    })
}

export const mainFileFetcher = async (method, formData, url) =>{
    return fetch( url, {
        method: method,
        mode: "cors",
        headers: {
            Authorization: await authHeader() 
        },
        body: formData,
    })
}