import { SERVER_URL } from "../../utils/server/server";
import { mainFetcher } from "../../utils/services/service";

export const getFaqs = async (pageNo , pageSize, sortField, sortDir) => {    
    const urlParams = 'pageNo=' + pageNo 
    +  '&pageSize=' + pageSize
    + '&sortField=' + sortField
    + '&sortDir=' + sortDir

    return mainFetcher('get', {}, SERVER_URL + '/api/faq/?' + urlParams )
};

export const updateFaq = async (faq) => {
    return mainFetcher('put', faq, SERVER_URL + '/api/faq/')
};

export const createFaq = async (faq) => {
    return mainFetcher('post', faq, SERVER_URL + '/api/faq/')
};

export const getFaq = async (faqId) => {
    return mainFetcher('get', {}, SERVER_URL + '/api/faq/' + faqId)
};

export const deleteFaq = async (faqId) => {
    return mainFetcher('delete', {}, SERVER_URL + '/api/faq/' + faqId)
};

export const getFaqsByUsername = async (username, pageNo , pageSize, sortField, sortDir) => {
    const urlParams = 'pageNo=' + pageNo 
    +  '&pageSize=' + pageSize
    + '&sortField=' + sortField
    + '&sortDir=' + sortDir

    return mainFetcher('get', {}, SERVER_URL + '/api/faq/username/' + username + '?' + urlParams )
};

export const getFaqsByUserId = async (userId, pageNo , pageSize, sortField, sortDir) => {
    const urlParams = 'pageNo=' + pageNo 
    +  '&pageSize=' + pageSize
    + '&sortField=' + sortField
    + '&sortDir=' + sortDir

    return mainFetcher('get', {}, SERVER_URL + '/api/faq/user/' + userId + '?' + urlParams )
};

export const deleteFaqsByUserId = async (userId) => {
    return mainFetcher('delete', {}, SERVER_URL + '/api/faq/' + userId)
};

export const getFaqsCount = async () => {
    return mainFetcher('get', {}, SERVER_URL + '/api/faq/count')
};

export const getFaqsCountByUserId = async (userId) => {
    return mainFetcher('get', {}, SERVER_URL + '/api/faq/count-by-user-id/' + userId)
};