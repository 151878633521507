import { SERVER_URL } from "../../utils/server/server";
import { mainFetcher } from "../../utils/services/service";

export const getPackages = async (pageNo , pageSize, sortField, sortDir) => {
    const urlParams = 'pageNo=' + pageNo 
    +  '&pageSize=' + pageSize
    + '&sortField=' + sortField
    + '&sortDir=' + sortDir

    return mainFetcher('get', {}, SERVER_URL + '/api/package/?' + urlParams )
};

export const updatePackage = async (aPackage) => {
    return mainFetcher('put', aPackage, SERVER_URL + '/api/package/')
};

export const createPackage = async (aPackage) => {
    return mainFetcher('post', aPackage, SERVER_URL + '/api/package/')
};

export const getPackage = async (packageId) => {
    return mainFetcher('get', {}, SERVER_URL + '/api/package/' + packageId)
};

export const deletePackage = async (packageId) => {
    return mainFetcher('delete', {}, SERVER_URL + '/api/package/' + packageId)
};

export const getPackagesByUsername = async (username, pageNo , pageSize, sortField, sortDir) => {
    const urlParams = 'pageNo=' + pageNo 
    +  '&pageSize=' + pageSize
    + '&sortField=' + sortField
    + '&sortDir=' + sortDir

    return mainFetcher('get', {}, SERVER_URL + '/api/package/username/' + username + '?' + urlParams )
};

export const getPackagesByUserId = async (userId, pageNo , pageSize, sortField, sortDir) => {
    
    const urlParams = 'pageNo=' + pageNo 
    +  '&pageSize=' + pageSize
    + '&sortField=' + sortField
    + '&sortDir=' + sortDir

    return mainFetcher('get', {}, SERVER_URL + '/api/package/user/' + userId + '?' + urlParams )
};

export const deletePackagesByUserId = async (userId) => {
    return mainFetcher('delete', {}, SERVER_URL + '/api/package/user/' + userId)
};

export const getPackagesCount = async () => {
    return mainFetcher('get', {}, SERVER_URL + '/api/package/count')
};

export const getPackagesCountByUserId = async (userId) => {
    return mainFetcher('get', {}, SERVER_URL + '/api/package/count-by-user-id/' + userId)
};