import { getCertification } from "../../../certifications/services/certification.service"

export const certificationToEditLoader = async ({params}) => {
    try {
        const certificationId = params.certificationId

        const result = await getCertification(certificationId)
        const response = await result.json()
        if(!response.success){
            return {
                errorCode: result.status,
                loaderMessage: 'Page Not Found',
                loaderDetails: result.status === 404 ? 'The requested resource could not be found on this server. Please verify your request and try again.': response.message,
                certification: null,
                success: false,
                actionText: 'Go To Home Page',
                action: '/'
            }
        }

        return {
            errorCode: 200,
            loaderMessage: 'OK',
            loaderDetails: response.message,
            certification: response.data.certification,
            success: true,
            actionText: 'Success',
            action: ''
        }
        
    } catch (error) {
        return {
            errorCode: 0,
            loaderMessage: 'You are offline',
            loaderDetails: 'Please check your connection and try again.',
            certification: null,
            success: false,
            actionText: 'Retry',
            action: '/'
        }
    }
}

export const certificationToAddLoader = async ({params}) => {
    return {
        errorCode: 200,
        loaderMessage: '',
        loaderDetails: '',
        certification: null,
        success: true,
        actionText: 'Success',
        action: '/'
    }
}