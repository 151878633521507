import { getPostByPostName } from "../../../../blog/services/post.service"

export const postLoader = async ({params}) => {

    try {
        const postName = params.postName

        const result = await getPostByPostName(postName, true)
        const response = await result.json()
        if(!response.success){
            return {
                errorCode: result.status,
                message: 'Page Not Found',
                details: result.status === 404 ? 'The requested resource could not be found on this server. Please verify your request and try again.': response.message,
                post: null,
                success: false,
                actionText: 'View Posts',
                action: '/posts'
            }
        }

        return {
            errorCode: 200,
            message: 'OK',
            details: response.message,
            post: response.data.post,
            success: true,
            actionText: 'Success',
            action: ''
        }
        
    } catch (error) {
        return {
            errorCode: 0,
            message: 'You are offline',
            details: 'Please check your connection and try again.',
            post: null,
            success: false,
            actionText: 'Retry',
            action: '/posts'
        }
    }
}