import { SERVER_URL } from "../../utils/server/server";
import { mainFetcher, mainFileFetcher } from "../../utils/services/service";

export const setProjectImage = async (data , projectId) => {
    return mainFileFetcher('put', data, SERVER_URL + '/api/project/set-project-image/' + projectId)
};

export const getProjects = async (search, published, pageNo , pageSize, sortField, sortDir) => {
    published = published !== null ? '&published=' + published : ''

    const urlParams = 'pageNo=' + pageNo 
    +  '&pageSize=' + pageSize
    + '&sortField=' + sortField
    + '&sortDir=' + sortDir
    + '&search=' + search
    + published

    return mainFetcher('get', {}, SERVER_URL + '/api/project/?' + urlParams )
};

export const updateProject = async (project) => {
    return mainFetcher('put', project, SERVER_URL + '/api/project/')
};

export const createProject = async (project) => {
    return mainFetcher('post', project, SERVER_URL + '/api/project/')
};

export const getProject = async (projectId, published) => {
    published = published !== null ? 'published=' + published : ''

    const urlParams =  published
    return mainFetcher('get', {}, SERVER_URL + '/api/project/' + projectId + "?" + urlParams)
};

export const getProjectByProjectName = async (projectName, published) => {
    published = published !== null ? 'published=' + published : ''

    const urlParams =  published
    return mainFetcher('get', {}, SERVER_URL + '/api/project/get-by-project-name/' + projectName + "?" + urlParams)
};

export const deleteProject = async (projectId) => {
    return mainFetcher('delete', {}, SERVER_URL + '/api/project/' + projectId)
};

export const getProjectsByUserId = async ( userId, search, published, pageNo , pageSize, sortField, sortDir) => {
    published = published !== null ? '&published=' + published : ''
    
    const urlParams = 'pageNo=' + pageNo 
    +  '&pageSize=' + pageSize
    + '&sortField=' + sortField
    + '&sortDir=' + sortDir
    + '&search=' + search
    + published

    return mainFetcher('get', {}, SERVER_URL + '/api/project/user/' + userId + '?' + urlParams )
};

export const getProjectsByUserIdAndResumePriority = async (userId, priority, published, pageNo , pageSize, sortField, sortDir) => {
    published = published !== null ? '&published=' + published : ''
    
    const urlParams = 'pageNo=' + pageNo 
    +  '&pageSize=' + pageSize
    + '&sortField=' + sortField
    + '&sortDir=' + sortDir
    + '&priority=' + priority
    + published

    return mainFetcher('get', {}, SERVER_URL + '/api/project/user/' + userId + '/resume?' + urlParams )
};

export const getProjectsByUserIdAndCategoryId = async (userId, categoryId, search, published, pageNo , pageSize, sortField, sortDir) => {
    published = published !== null ? '&published=' + published : ''
    
    const urlParams = 'pageNo=' + pageNo 
    +  '&pageSize=' + pageSize
    + '&sortField=' + sortField
    + '&sortDir=' + sortDir
    + '&search=' + search
    + published

    return mainFetcher('get', {}, SERVER_URL + '/api/project/user/' + userId + '/category/' + categoryId + '?' + urlParams )
};

export const getProjectsCount = async () => {
    return mainFetcher('get', {}, SERVER_URL + '/api/project/count')
};

export const getProjectsCountByUserId = async (userId) => {
    return mainFetcher('get', {}, SERVER_URL + '/api/project/count-by-user-id/' + userId)
};

export const getProjectsCountByCategoryId = async (categoryId) => {
    return mainFetcher('get', {}, SERVER_URL + '/api/project/count-by-category-id/' + categoryId)
};

export const getProjectsByClientId = async (clientId, published, pageNo , pageSize, sortField, sortDir) => {
    published = published !== null ? '&published=' + published : ''
    
    const urlParams = 'pageNo=' + pageNo 
    +  '&pageSize=' + pageSize
    + '&sortField=' + sortField
    + '&sortDir=' + sortDir
    + published

    return mainFetcher('get', {}, SERVER_URL + '/api/project/client/ ' + clientId + '?' + urlParams )
};

export const getProjectsByCategoryId = async (categoryId, search, published, pageNo , pageSize, sortField, sortDir) => {
    published = published !== null ? '&published=' + published : ''
    
    const urlParams = 'pageNo=' + pageNo 
    +  '&pageSize=' + pageSize
    + '&sortField=' + sortField
    + '&sortDir=' + sortDir
    + '&search=' + search
    + published

    return mainFetcher('get', {}, SERVER_URL + '/api/project/category/ ' + categoryId + '?' + urlParams )
};

export const deleteProjectImage = async (projectId) => {
    return mainFetcher('delete', {}, SERVER_URL + '/api/project/delete-post-image/' + projectId)
};