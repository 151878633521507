import { DEFAULT_APP_PAGE_NO, DEFAULT_APP_PAGE_SIZE } from "../defaults/default-values";
import { BEDUBYTE_ACTION_TYPES } from "../types/bedubyte.types";

const BEDUBYTE_INITIAL_STATE = {
    profile: {
      id: 4,
      username: "eastearn",
      email: "eastearnapp@gmail.com",
      tagline: "",
      intro: "<p>Welcome to Bedubyte, Your Complete Professional Showcase and Collaboration Platform!</p>",
      profilePic: "Pro Portfolios-1690838738663.png",
      company: "",
      mobile: 750493762,
      publicProfile: true,
      publicResume: true,
      publicEmail: false,
      publicMobile: true,
      bio: "<p>Empower your career and seize unparalleled opportunities with Bedubyte. Our comprehensive platform is your gateway to success, enabling you to showcase your skills, projects, and experience to potential clients and employers, all while discovering a world of talented professionals and captivating portfolios</p>",
      whatIDo: "<p>Empower your career and unleash your potential with Bedubyte. Our all-in-one app revolutionizes the way you showcase your skills, connect with industry experts, and grow your professional network.</p>",
      careerSummary: null,
      twitterUrl: "https://twitter.com/bedubyte",
      githubUrl: null,
      linkedInUrl: null,
      stackoverflowUrl: null,
      codePenUrl: null,
      instagramUrl: "https://www.instagram.com/bedubyte",
      facebookUrl: "https://web.facebook.com/bedubyte",
      mediumUrl: null,
      youtubeUrl: null,
      website: "https://bedubyte.com/bedubyte",
      fname: "Bedubyte",
      lname: "LLC"
    },
    pagination: {
        pageNo: DEFAULT_APP_PAGE_NO ,
        pageSize: DEFAULT_APP_PAGE_SIZE,
        sortField: 'createdAt',
        sortDir: 'desc',
        totalItems: 0,
        totalPages: 0,
        reverseSortDir: 'asc'
    },
    search: '',
    currentPage: 'home',
    softRefresh: new Date(),
    notificationModal: {
        show: false,
        object: null,
        objectType: 'user',
        totalUnseenNotifications: 0
    },
    shareModal: {
        show: false,
        title: 'Share',
        object: null,
        objectType: '',
        link: '',
        text: ''
    }
}

export const bedubyteReducer = (state = BEDUBYTE_INITIAL_STATE, action) => {
    const {type, payload} = action

    switch (type) {
        case BEDUBYTE_ACTION_TYPES.SET_BEDUBYTE_PROFILE:
            return {...state, profile: payload}
        case BEDUBYTE_ACTION_TYPES.SET_BEDUBYTE_PAGINATION:
            return {...state, pagination: payload}
        case BEDUBYTE_ACTION_TYPES.RESET_BEDUBYTE_PAGINATION:
                return {...state, pagination: BEDUBYTE_INITIAL_STATE.pagination}
        case BEDUBYTE_ACTION_TYPES.SET_BEDUBYTE_SEARCH:
            return {...state, search: payload}
        case BEDUBYTE_ACTION_TYPES.SET_BEDUBYTE_CURRENT_PAGE:
            return {...state, currentPage: payload}
        case BEDUBYTE_ACTION_TYPES.SET_APP_SOFT_REFRESH:
                return {...state, softRefresh: payload}
        case BEDUBYTE_ACTION_TYPES.SET_SHARE_MODAL:
            return {...state, shareModal: payload}
        case BEDUBYTE_ACTION_TYPES.SET_NOTIFICATION_MODAL:
            return {...state, notificationModal: payload}
        case BEDUBYTE_ACTION_TYPES.SET_BEDUBYTE:
                return payload
        default:
            return state;
    }
}