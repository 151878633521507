import { SERVER_URL } from "../utils/server/server";
import { mainFetcher } from "../utils/services/service";

export const updateNotification = async (notification) => {
    return mainFetcher('put', notification, SERVER_URL + '/api/notification/update')
};

export const notifyUsersByEnabled = async (notification) => {
    return mainFetcher('post', notification, SERVER_URL + '/api/notification/notify-users-by-enabled')
};

export const notifyUsersByEmailVerified = async (notification) => {
    return mainFetcher('post', notification, SERVER_URL + '/api/notification/notify-users-by-email-verified')
};

export const notifyUsersByAccountNonLocked = async (notification) => {
    return mainFetcher('post', notification, SERVER_URL + '/api/notification/notify-users-by-account-non-locked')
};

export const notifyListedUsers = async (notification) => {
    return mainFetcher('post', notification, SERVER_URL + '/api/notification/notify-listed-users')
};

export const notifyAllUsers = async (notification) => {
    return mainFetcher('post', notification, SERVER_URL + '/api/notification/notify-all-users')
};

export const getNotificationById = async (notificationId) => {
    return mainFetcher('get', {}, SERVER_URL + '/api/notification/' + notificationId)
};

export const deleteNotificationById = async (notificationId) => {
    return mainFetcher('delete', {}, SERVER_URL + '/api/notification/' + notificationId)
};

export const getNotificationsByUserId = async (userId, isRead, seen, type, pageNo , pageSize, sortField, sortDir) => {
    
    isRead = isRead !== null ? '&isRead=' + isRead : ''
    seen = seen !== null ? '&seen=' + seen : ''
    type = type !== null ? '&type=' + type : ''

    const urlParams = 'pageNo=' + pageNo 
    +  '&pageSize=' + pageSize
    + '&sortField=' + sortField
    + '&sortDir=' + sortDir
    + isRead
    + seen
    + type

    return mainFetcher('get', {}, SERVER_URL + '/api/notification/user/' + userId + '?' + urlParams )
};

export const getAllNotifications = async (type, pageNo , pageSize, sortField, sortDir) => {
    type = type !== null ? '&type=' + type : ''

    const urlParams = 'pageNo=' + pageNo 
    +  '&pageSize=' + pageSize
    + '&sortField=' + sortField
    + '&sortDir=' + sortDir
    + type

    return mainFetcher('get', {}, SERVER_URL + '/api/notification/find-all' + '?' + urlParams )
};

export const getNotificationsBySeen = async ( seen, type, pageNo , pageSize, sortField, sortDir) => {
    seen = seen !== null ? '&seen=' + seen : ''
    type = type !== null ? '&type=' + type : ''

    const urlParams = 'pageNo=' + pageNo 
    +  '&pageSize=' + pageSize
    + '&sortField=' + sortField
    + '&sortDir=' + sortDir
    + seen
    + type

    return mainFetcher('get', {}, SERVER_URL + '/api/notification/find-all-by-seen' + '?' + urlParams )
};

export const getNotificationsByIsRead = async ( isRead, type, pageNo , pageSize, sortField, sortDir) => {
    
    isRead = isRead !== null ? '&isRead=' + isRead : ''
    type = type !== null ? '&type=' + type : ''

    const urlParams = 'pageNo=' + pageNo 
    +  '&pageSize=' + pageSize
    + '&sortField=' + sortField
    + '&sortDir=' + sortDir
    + isRead
    + type

    return mainFetcher('get', {}, SERVER_URL + '/api/notification/find-all-by-is-read' + '?' + urlParams )
};

export const getNotificationsByIsReadAndSeen = async ( isRead, seen, type, pageNo , pageSize, sortField, sortDir) => {
    
    isRead = isRead !== null ? '&isRead=' + isRead : ''
    seen = seen !== null ? '&seen=' + seen : ''
    type = type !== null ? '&type=' + type : ''

    const urlParams = 'pageNo=' + pageNo 
    +  '&pageSize=' + pageSize
    + '&sortField=' + sortField
    + '&sortDir=' + sortDir
    + isRead
    + seen
    + type

    return mainFetcher('get', {}, SERVER_URL + '/api/notification/find-all-by-is-read-and-seen' + '?' + urlParams )
};

export const countAllNotifications = async (type) => {
    type = type !== null ? '&type=' + type : ''

    const urlParams = type

    return mainFetcher('get', {}, SERVER_URL + '/api/notification/count' + '?' + urlParams)
};


export const countNotificationsByUserId = async (userId, isRead, seen, type) => {
    
    isRead = isRead !== null ? '&isRead=' + isRead : ''
    seen = seen !== null ? '&seen=' + seen : ''
    type = type !== null ? '&type=' + type : ''

    const urlParams = isRead + seen + type

    return mainFetcher('get', {}, SERVER_URL + '/api/notification/count-by-user/' + userId + '?' + urlParams )
};

export const countNotificationsBySeen = async (seen, type) => {
    
    seen = seen !== null ? '&seen=' + seen : ''
    type = type !== null ? '&type=' + type : ''

    const urlParams = seen + type

    return mainFetcher('get', {}, SERVER_URL + '/api/notification/count-by-seen' + '?' + urlParams )
};

export const countNotificationsByIsRead = async (isRead, type) => {
    
    isRead = isRead !== null ? '&isRead=' + isRead : ''
    type = type !== null ? '&type=' + type : ''

    const urlParams = isRead + type

    return mainFetcher('get', {}, SERVER_URL + '/api/notification/count-by-is-read' + '?' + urlParams )
};

export const countNotificationsByIsReadAndSeen = async ( isRead, seen, type) => {
    
    isRead = isRead !== null ? '&isRead=' + isRead : ''
    seen = seen !== null ? '&seen=' + seen : ''
    type = type !== null ? '&type=' + type : ''

    const urlParams = isRead + seen + type

    return mainFetcher('get', {}, SERVER_URL + '/api/notification/count-by-is-read-and-seen' + '?' + urlParams )
};
