import { SERVER_URL } from "../../utils/server/server";
import { mainFetcher, mainFileFetcher } from "../../utils/services/service";


export const getPromos = async (promoType, published, pageNo , pageSize, sortField, sortDir) => {   
    promoType = promoType !== null ? '&promoType=' + promoType : ''
    published = published !== null ? '&published=' + published : ''

    const urlParams = 'pageNo=' + pageNo 
    +  '&pageSize=' + pageSize
    + '&sortField=' + sortField
    + '&sortDir=' + sortDir
    + promoType
    + published

    return mainFetcher('get', {}, SERVER_URL + '/api/promo/?' + urlParams )
};

export const updatePromo = async (promo) => {
    return mainFileFetcher('put', promo, SERVER_URL + '/api/promo/')
};

export const createPromo = async (promo) => {
    return mainFileFetcher('post', promo, SERVER_URL + '/api/promo/')
};

export const getPromo = async (promoId) => {
    return mainFetcher('get', {}, SERVER_URL + '/api/promo/' + promoId)
};

export const getRandomPromo = async () => {
    return mainFetcher('get', {}, SERVER_URL + '/api/promo/find-random')
};

export const getPlatformsRandomPromo = async () => {
    return mainFetcher('get', {}, SERVER_URL + '/api/promo/find-platforms-random')
};

export const getRandomPromoByUserId = async (userId) => {
    return mainFetcher('get', {}, SERVER_URL + '/api/promo/find-random-by-user-id/' + userId)
};

export const getRandomPromoByUserIdOrOther = async (userId) => {
    return mainFetcher('get', {}, SERVER_URL + '/api/promo/find-random-by-user-id-or-other/' + userId)
};

export const getRandomPromoByUserIdOrPlatforms = async (userId) => {
    return mainFetcher('get', {}, SERVER_URL + '/api/promo/find-random-by-user-id-or-platforms/' + userId)
};

export const deletePromo = async (promoId) => {
    return mainFetcher('delete', {}, SERVER_URL + '/api/promo/' + promoId)
};

export const getPromosByUserId = async (userId, promoType, published, pageNo , pageSize, sortField, sortDir) => {
    promoType = promoType !== null ? '&promoType=' + promoType : ''
    published = published !== null ? '&published=' + published : ''

    const urlParams = 'pageNo=' + pageNo 
    +  '&pageSize=' + pageSize
    + '&sortField=' + sortField
    + '&sortDir=' + sortDir
    + promoType
    + published

    return mainFetcher('get', {}, SERVER_URL + '/api/promo/user/' + userId + '?' + urlParams )
};

export const getExpiredPromosByUserId = async (userId, published, pageNo , pageSize, sortField, sortDir) => {
    published = published !== null ? '&published=' + published : ''

    const urlParams = 'pageNo=' + pageNo 
    +  '&pageSize=' + pageSize
    + '&sortField=' + sortField
    + '&sortDir=' + sortDir
    + published

    return mainFetcher('get', {}, SERVER_URL + '/api/promo/user/' + userId + '/expired?' + urlParams )
};

export const getActivePromosByUserId = async (userId, published, pageNo , pageSize, sortField, sortDir) => {
    published = published !== null ? '&published=' + published : ''

    const urlParams = 'pageNo=' + pageNo 
    +  '&pageSize=' + pageSize
    + '&sortField=' + sortField
    + '&sortDir=' + sortDir
    + published

    return mainFetcher('get', {}, SERVER_URL + '/api/promo/user/' + userId + '/active?' + urlParams )
};

export const getActivePromos = async (published, pageNo , pageSize, sortField, sortDir) => {
    published = published !== null ? '&published=' + published : ''

    const urlParams = 'pageNo=' + pageNo 
    +  '&pageSize=' + pageSize
    + '&sortField=' + sortField
    + '&sortDir=' + sortDir
    + published

    return mainFetcher('get', {}, SERVER_URL + '/api/promo/active?' + urlParams )
};

export const deletePromosByUserId = async (userId) => {
    return mainFetcher('delete', {}, SERVER_URL + '/api/promo/user/' + userId)
};

export const countPromos = async () => {
    return mainFetcher('get', {}, SERVER_URL + '/api/promo/count')
};

export const addPromoClicks = async (promoId, clicks) => {
    return mainFetcher('put', {}, SERVER_URL + '/api/promo/add-promo-clicks/' + promoId + '/' + clicks)
};

export const countPromosByUserId = async (userId) => {
    return mainFetcher('get', {}, SERVER_URL + '/api/promo/count-by-user-id/' + userId)
};

export const getPromoTypeEnum = async () => {
    return mainFetcher('get', {}, SERVER_URL + '/api/promo/promo-type-enum/')
};