import { SERVER_URL } from "../../utils/server/server";
import { mainFetcher } from "../../utils/services/service";

export const getProjectCategories = async (pageNo , pageSize, sortField, sortDir, search) => {
    const urlParams = 'pageNo=' + pageNo 
    +  '&pageSize=' + pageSize
    + '&sortField=' + sortField
    + '&sortDir=' + sortDir
    + '&search=' + search

    return mainFetcher('get', {}, SERVER_URL + '/api/project-categories/?' + urlParams )
};

export const updateProjectCategory = async (projectCategory) => {
    return mainFetcher('put', projectCategory, SERVER_URL + '/api/project-categories/')
};

export const createProjectCategory = async (projectCategory) => {
    return mainFetcher('post', projectCategory, SERVER_URL + '/api/project-categories/')
};

export const getProjectCategory = async (projectCategoryId) => {
    return mainFetcher('get', {}, SERVER_URL + '/api/project-categories/' + projectCategoryId)
};

export const deleteProjectCategory = async (projectCategoryId) => {
    return mainFetcher('delete', {}, SERVER_URL + '/api/project-categories/' + projectCategoryId)
};

export const getProjectCategoriesCount = async () => {
    return mainFetcher('get', {}, SERVER_URL + '/api/project-categories/count')
};